
class JacksonUtils {

    static stringifyArgs(args:any): string {
        var str:string = "";
        for (var key of Object.keys(args)) {
            str += key + "='" + args[key] + "' ";
        }

        return str;
    }

    static random255() {
        return Math.floor(Math.random() * 256);
    }

    static decimalToHexByte(d: number) {
        return d.toString(16).padStart(2, '0');
    }

    static randomInt(min:number, max:number) {
        return Math.floor(min + (Math.random() * (max - min)));
    }

}

class JacksonColor {      

    static randHexColor() {
        return '#'+(Math.random() * 0xFFFFFF << 0).toString(16).padStart(6, '0');
    }

    static hexToRgb(hex: string) {
        const r = parseInt(hex.substring(1,3), 16);
        const g = parseInt(hex.substring(3,5), 16);
        const b = parseInt(hex.substring(5,7), 16);
        return {r: r, g: g, b: b};
    }

    static componentToHex(c:number) {
        var hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
      }
      
    static rgbToHex(rgb: any) {
        return "#" + JacksonColor.componentToHex(rgb.r) + JacksonColor.componentToHex(rgb.g) + JacksonColor.componentToHex(rgb.b);
      }

    hexCode: string;
    rgb: any;

    // either a hex string, rgb object, or nothing for random
    constructor(args?:any) {
        if (args) {
            if (args.hexCode) {
                this.hexCode = args.hexCode;
                this.rgb = JacksonColor.hexToRgb(args.hexCode);
            }
            else if (args.rgb) {
                this.rgb = args.rgb;
                this.hexCode = JacksonColor.rgbToHex(this.rgb);
            }
            else {
                this.rgb = {r: 0, g: 0, b: 0};
                this.hexCode = JacksonColor.rgbToHex(this.rgb);
            }
        }
        else {
            this.hexCode = JacksonColor.randHexColor();
            this.rgb = JacksonColor.hexToRgb(this.hexCode);
        }
    }

    toString() {
        return "rgb(" + this.rgb.r + "," + this.rgb.g + "," + this.rgb.b + ")";
    }
}

export { JacksonUtils, JacksonColor };