import { ActionType, MintAction } from './MintAction';
import ArtParams from '../../ArtParams.json';
import { JacksonUtils } from "../utils";
import { JacksonByteParam } from "./JacksonParam";
import Canvas from './Canvas'

type EffectArgs = {
    id: number,
}

type RotateEffectArgs = EffectArgs & {
    rotationDegrees: number
}

type QuadEffectArgs = EffectArgs & {
    amount: number;
}

type ShearEffectArgs = EffectArgs & {
    amount: number;
    slices: number;
}

type WindowRectangleArgs = EffectArgs & {
    x: number,
    y: number,
    width: number,
    height: number
}

type WindowCircleArgs = EffectArgs & {
    cx: number, 
    cy: number,
    r: number
}

abstract class Effect extends MintAction {
    id: number;
    constructor(_id: number | undefined) {
        super();
        if (_id !== undefined) {
            this.id = _id;

        } else {
            throw new Error("Effect must have an id");
        }
    }
}

class RotateEffect extends Effect {
    rotationDegrees: JacksonByteParam = new JacksonByteParam(0);

    constructor(args: RotateEffectArgs) {
        super(args.id);
        if (args && args.rotationDegrees !== undefined) {
            this.rotationDegrees.setDecimal(args.rotationDegrees);
        }
    }

    getSummary() {
        return `Rotate ${this.rotationDegrees.toValue(-180, 180)}°`
    }

    getSVG() {
        return `<g transform="rotate(${this.rotationDegrees.toValue(-180, 180)})" ><g>`;
    }

    getAnimatedSVG(): string {
        return `<g transform="rotate(${this.rotationDegrees.toValue(-180, 180)})" >` +
            `<animateTransform attributeName="transform" type="rotate" ${MintAction.ANIMATION_TIMING}` +
            ` values="0;${this.rotationDegrees.toValue(-180, 180)}" /><g>`;
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_ROTATE.value);
        bytes += this.rotationDegrees.getHex();
        return bytes;
    }

    getActionType(): ActionType {
        return ActionType.Rotate;
    }

    static fromByteString(byteString: string, effectId: number): RotateEffect {
        return new RotateEffect({
            id: effectId,
            rotationDegrees: parseInt(byteString.substring(2, 4), 16),
        });
    }
}

class QuadEffect extends Effect {
    amount: JacksonByteParam = new JacksonByteParam(0);

    constructor(args: QuadEffectArgs) {
        super(args.id);
        if (args) {
            if (args.amount !== undefined) this.amount.setDecimal(args.amount);
        }
    }

    getSummary(): string {
        return `Quad ${this.amount.toValue(ArtParams.QUAD_MIN_AMOUNT.value, ArtParams.QUAD_MAX_AMOUNT.value)}%`
    }

    getActionType(): ActionType {
        return ActionType.Quad;
    }

    getSVG(): string {
        var _a = this.amount.toValue(ArtParams.QUAD_MIN_AMOUNT.value, ArtParams.QUAD_MAX_AMOUNT.value);
            var svg = `<filter id="quad${this.id}" x="-140" y="-190" width="280" height="380">` +
            `<feOffset in="SourceGraphic" x="-140" y="-190" width="140" height="190" dx="${(-_a)}" dy="${(-_a)}" result="o1"/>` +
            `<feOffset in="SourceGraphic" x="0" y="-190" width="140" height="190" dx="${_a}" dy="${(-_a)}" result="o2"/>` +
            `<feOffset in="SourceGraphic" x="-140" y="0" width="140" height="190" dx="${(-_a)}" dy="${_a}" result="o3"/>` +
            `<feOffset in="SourceGraphic" x="0" y="0" width="140" height="190" dx="${_a}" dy="${_a}" result="o4"/>` +
            `<feMerge><feMergeNode in="o1"/><feMergeNode in="o2" /><feMergeNode in="o3"/><feMergeNode in="o4"/></feMerge>` +
            `</filter><g filter="url(#quad${this.id})"><g>`;
        return svg;
    }

    getAnimatedSVG(): string {
        var _a = this.amount.toValue(ArtParams.QUAD_MIN_AMOUNT.value, ArtParams.QUAD_MAX_AMOUNT.value);
        var svg = `<filter id="quadAnimate${this.id}" x="-140" y="-190" width="280" height="380">` +
            `<feOffset in="SourceGraphic" x="-140" y="-190" width="140" height="190" dx="${(-_a)}" dy="${(-_a)}" result="o1">` +
                `<animate attributeName="dx" ${MintAction.ANIMATION_TIMING} values="0;${-_a}" />` +
                `<animate attributeName="dy" ${MintAction.ANIMATION_TIMING} values="0;${-_a}" />` +
            `</feOffset>` +
            `<feOffset in="SourceGraphic" x="0" y="-190" width="140" height="190" dx="${_a}" dy="${(-_a)}" result="o2">` +
                `<animate attributeName="dx" ${MintAction.ANIMATION_TIMING} values="0;${_a}" />` +
                `<animate attributeName="dy" ${MintAction.ANIMATION_TIMING} values="0;${-_a}" />` +
            `</feOffset>` +
            `<feOffset in="SourceGraphic" x="-140" y="0" width="140" height="190" dx="${(-_a)}" dy="${_a}" result="o3">` +
                `<animate attributeName="dx" ${MintAction.ANIMATION_TIMING} values="0;${-_a}" />` +
                `<animate attributeName="dy" ${MintAction.ANIMATION_TIMING} values="0;${_a}" />` +
            `</feOffset>` +
            `<feOffset in="SourceGraphic" x="0" y="0" width="140" height="190" dx="${_a}" dy="${_a}" result="o4">` +
                `<animate attributeName="dx" ${MintAction.ANIMATION_TIMING} values="0;${_a}" />` +
                `<animate attributeName="dy" ${MintAction.ANIMATION_TIMING} values="0;${_a}" />` +
            `</feOffset>` +
            `<feMerge><feMergeNode in="o1"/><feMergeNode in="o2" /><feMergeNode in="o3"/><feMergeNode in="o4"/></feMerge>` +
            `</filter><g filter="url(#quadAnimate${this.id})"><g>`;
        return svg;
    }

    createByteString(): string {
        let bytes: string = ""
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_QUAD.value);
        bytes += this.amount.getHex();
        return bytes;
    }

    static fromByteString(byteString: string, jacksonId: number): QuadEffect {
        return new QuadEffect({
            id: jacksonId,
            amount: parseInt(byteString.substring(2, 4), 16),
        });
    }
}

class ShearEffect extends Effect {

    amount: JacksonByteParam = new JacksonByteParam(0);
    slices: JacksonByteParam = new JacksonByteParam();

    constructor(args: ShearEffectArgs) {
        super(args.id);
        if (args) {
            if (args.amount !== undefined) this.amount.setDecimal(args.amount);
            if (args.slices !== undefined) this.slices.setDecimal(args.slices);
        }
    }

    getSummary(): string {
        var q = this.slices.toValue(ArtParams.SHEAR_MIN_SLICES.value, ArtParams.SHEAR_MAX_SLICES.value)
        return `Shear ${q} slices by ${this.amount.toValue(ArtParams.SHEAR_MIN_AMOUNT.value, ArtParams.SHEAR_MAX_AMOUNT.value)} `;
    }

    getActionType(): ActionType {
        return ActionType.Shear;
    }

    getOffsetSVG(n: number, i: number, amount: number, animated: boolean): string {
        var width = Math.trunc(Canvas.PAINTABLE_WIDTH / n);
        var n100 = n * 100;
        var i100 = i * 100;
        var scaled = Math.trunc( (( Math.trunc((i100 - (n100 - 100)/2) / n)) * amount) / 100);

        if (animated) {
            return `<feOffset in="SourceGraphic" x="${(Canvas.PAINTABLE_MIN_X + width * i)}" y="-190"` +
                ` width="${width}" height="380" dx="0" dy="${scaled}" result="d${i}">` +
                `<animate attributeName="dy" ${MintAction.ANIMATION_TIMING} values="0;${scaled}" />` +
                `</feOffset>`
        }
        else {
            return `<feOffset in="SourceGraphic" x="${(Canvas.PAINTABLE_MIN_X + width * i)}" y="-190"` +
                ` width="${width}" height="380" dy="${scaled}" result="d${i}"/>`
        }
    }

    getOffsetsSVG(animated: boolean): string {
        var floodsSVG = "";
        var q = this.slices.toValue(ArtParams.SHEAR_MIN_SLICES.value, ArtParams.SHEAR_MAX_SLICES.value)
        for (var i = 0; i < q; i++) {
            floodsSVG += this.getOffsetSVG(q, i, this.amount.toValue(ArtParams.SHEAR_MIN_AMOUNT.value, ArtParams.SHEAR_MAX_AMOUNT.value), animated);
        }
        return floodsSVG;
    }

    getMergeSVG(): string {
        var mergeSVG = `<feMerge>`;
        for (var i = 0; i < this.slices.toValue(ArtParams.SHEAR_MIN_SLICES.value, ArtParams.SHEAR_MAX_SLICES.value); i++) {
            mergeSVG += `<feMergeNode in="d` + i + `"/>`;
        }
        return mergeSVG + "</feMerge>";
    }

    getSVG() {
        return `<filter id="shear${this.id}" ` +
            `x="${Canvas.PAINTABLE_MIN_X}" y="${Canvas.PAINTABLE_MIN_Y}" ` +
            `width="${Canvas.PAINTABLE_WIDTH}" height="${Canvas.PAINTABLE_HEIGHT}">` +
            this.getOffsetsSVG(false) + this.getMergeSVG() +
            `</filter><g filter="url(#shear${this.id})"><g>`
    }

    getAnimatedSVG(): string {
        return `<filter id="shear${this.id}" primitiveUnits="userSpaceOnUse" ` +
            `x="${Canvas.PAINTABLE_MIN_X}" width="${Canvas.PAINTABLE_WIDTH}" ` +
            `y="${Canvas.PAINTABLE_MIN_Y}" height="${Canvas.PAINTABLE_HEIGHT}">` +
            this.getOffsetsSVG(true) + this.getMergeSVG() +
            `</filter><g filter="url(#shear${this.id})"><g>`
    }

    createByteString(): string {
        let bytes: string = ""
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_SHEAR.value);
        bytes += this.amount.getHex();
        bytes += this.slices.getHex();
        return bytes;
    }

    static fromByteString(byteString: string, jacksonId: number): ShearEffect {
        return new ShearEffect({
            id: jacksonId,
            amount: parseInt(byteString.substring(2, 4), 16),
            slices: parseInt(byteString.substring(4, 6), 16),
        });
    }
}

class WindowRectangleEffect extends Effect {
    x: JacksonByteParam = new JacksonByteParam();
    y: JacksonByteParam = new JacksonByteParam();
    width: JacksonByteParam = new JacksonByteParam();
    height: JacksonByteParam = new JacksonByteParam();

    constructor(args: WindowRectangleArgs) {
        super(args.id);
        if (args) {
            if (args.x !== undefined) this.x.setDecimal(args.x);
            if (args.y !== undefined) this.y.setDecimal(args.y);
            if (args.width !== undefined) this.width.setDecimal(args.width);
            if (args.height !== undefined) this.height.setDecimal(args.height);
        }
    }

    getSummary(): string {
        return "Window Rectangle"
    }

    getActionType(): ActionType {
        return ActionType.WindowRectangle;
    }

    getOutlineElement(): Node {
        let args = {
            x: this.x.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.y.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.width.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_WIDTH),
            height: this.height.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_HEIGHT),
        }
        var outline = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        outline.setAttribute("x", args.x.toString());
        outline.setAttribute("y", args.y.toString());
        outline.setAttribute("width", args.width.toString());
        outline.setAttribute("height", args.height.toString());
        outline.setAttribute("stroke", "grey");
        outline.setAttribute("stroke-width", "0.5");
        outline.setAttribute("fill", "none");
        outline.setAttribute("opacity", "0");
        
        var outlineFade = document.createElementNS("http://www.w3.org/2000/svg", "animate");
        outlineFade.setAttribute("attributeName", "opacity");
        outlineFade.setAttribute("values", "1;1;0");
        outlineFade.setAttribute("dur", "1s");

        outline.appendChild(outlineFade);

        return outline;
    }

    getSVG() {
        let args = {
            x: this.x.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.y.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.width.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_WIDTH),
            height: this.height.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_HEIGHT),
        }
        return `<mask id='maskr${this.id}'>` +
            `<rect ${JacksonUtils.stringifyArgs(args)}fill='white'/>` +
            `</mask>` +
            `<g mask='url(#maskr${this.id})'><g>`;
    }

    getAnimatedSVG(): string {
        let args = {
            x: this.x.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.y.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.width.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_WIDTH),
            height: this.height.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_HEIGHT),
        }
        return `<mask id="maskr${this.id}">` +
        `<rect ${JacksonUtils.stringifyArgs(args)} fill="white">` +
            `<animate attributeName="x" ${MintAction.ANIMATION_TIMING} values="${Canvas.PAINTABLE_MIN_X};${args.x}" />` +
            `<animate attributeName="y" ${MintAction.ANIMATION_TIMING} values="${Canvas.PAINTABLE_MIN_Y};${args.y}" />` +
            `<animate attributeName="width" ${MintAction.ANIMATION_TIMING} values="${Canvas.PAINTABLE_WIDTH};${args.width}" />` +
            `<animate attributeName="height" ${MintAction.ANIMATION_TIMING} values="${Canvas.PAINTABLE_HEIGHT};${args.height}" />` +
        `</rect>` +
        `</mask>` +
        `<g mask="url(#maskr${this.id})"><g>`;
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_WINDOW_RECTANGLE.value);
        bytes += this.x.getHex();
        bytes += this.y.getHex();
        bytes += this.width.getHex();
        bytes += this.height.getHex();
        return bytes;
    }

    static fromByteString(byteString: string, hourId: number): WindowRectangleEffect {
        return new WindowRectangleEffect({
            id: hourId,
            x: parseInt(byteString.substring(2, 4), 16),
            y: parseInt(byteString.substring(4, 6), 16),
            width: parseInt(byteString.substring(6, 8), 16),
            height: parseInt(byteString.substring(8, 10), 16),
        });
    }
}

class WindowCircleEffect extends Effect {
    cx: JacksonByteParam = new JacksonByteParam();
    cy: JacksonByteParam = new JacksonByteParam();
    r: JacksonByteParam = new JacksonByteParam();

    constructor(args: WindowCircleArgs) {
        super(args.id);
        if (args) {
            if (args.cx !== undefined) this.cx.setDecimal(args.cx);
            if (args.cy !== undefined) this.cy.setDecimal(args.cy);
            if (args.r !== undefined) this.r.setDecimal(args.r);
        }
    }

    getSummary(): string {
        return "Window Circle"
    }

    getActionType(): ActionType {
        return ActionType.WindowCircle;
    }

    getSVG() {
        let args = {
            cx: this.cx.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            cy: this.cy.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            r: this.r.toValue(ArtParams.WINDOW_CIRCLE_MIN_RADIUS.value, ArtParams.CIRCLE_MAX_RADIUS.value),
        }

        return `<mask id='maskc${this.id}'>` +
            `<circle ${JacksonUtils.stringifyArgs(args)}fill='white'/>` +
            `</mask>` +
            `<g mask='url(#maskc${this.id})'><g>`;
    }

    getAnimatedSVG(): string {
        let args = {
            cx: this.cx.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            cy: this.cy.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            r: this.r.toValue(ArtParams.WINDOW_CIRCLE_MIN_RADIUS.value, ArtParams.CIRCLE_MAX_RADIUS.value),
        }

        return `<mask id='maskc${this.id}'>'` +
            `<circle ${JacksonUtils.stringifyArgs(args)} fill="white">` +
                `<animate attributeName="r" ${MintAction.ANIMATION_TIMING} values="${Canvas.PAINTABLE_WIDTH};${args.r}" />` +
            `</circle>` +
            `</mask>` +
            `<g mask='url(#maskc${this.id})'><g>`;
    }

    getOutlineElement(): Node {
        let args = {
            x: this.cx.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.cy.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.r.toValue(ArtParams.WINDOW_CIRCLE_MIN_RADIUS.value, ArtParams.CIRCLE_MAX_RADIUS.value),
        }
        var outline = document.createElementNS("http://www.w3.org/2000/svg", "circle");
        outline.setAttribute("cx", args.x.toString());
        outline.setAttribute("cy", args.y.toString());
        outline.setAttribute("r", args.width.toString());
        outline.setAttribute("stroke", "grey");
        outline.setAttribute("fill", "none");
        outline.setAttribute("opacity", "0");
        outline.setAttribute("stroke-width", "0.5");
        
        var outlineFade = document.createElementNS("http://www.w3.org/2000/svg", "animate");
        outlineFade.setAttribute("attributeName", "opacity");
        outlineFade.setAttribute("values", "1;1;0");
        outlineFade.setAttribute("dur", "1s");

        outline.appendChild(outlineFade);

        return outline;
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_WINDOW_CIRCLE.value);
        bytes += this.cx.getHex();
        bytes += this.cy.getHex();
        bytes += this.r.getHex();
        return bytes;
    }

    static fromByteString(byteString: string, hourId: number): WindowCircleEffect {
        return new WindowCircleEffect({
            id: hourId,
            cx: parseInt(byteString.substring(2, 4), 16),
            cy: parseInt(byteString.substring(4, 6), 16),
            r: parseInt(byteString.substring(6, 8), 16),
        });
    }
}


export { Effect, RotateEffect, ShearEffect, QuadEffect, WindowRectangleEffect, WindowCircleEffect };