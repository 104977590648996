import { JacksonUtils } from "../utils";


class JacksonByteParam {
    
    private decimal: number;
    constructor(d?: number) {
        if (d) {
            if (d > 255 || d < 0) {
                console.log("ERROR: trying to create byte param ", d);
            }
            this.decimal = parseInt("" + d);
        }
        else {
            this.decimal = Math.floor(Math.random() * 256);
        }
    }

    setDecimal(d: number) {
        this.decimal = parseInt("" + d);
    }
    
    randomize() {
        this.decimal = JacksonUtils.random255();
    }

    getHex(): string {
        return JacksonUtils.decimalToHexByte(this.decimal); //this.decimal.toString(16).padStart(2, '0');
    }

    toValue(min: number, max: number): number {
        //console.log("rotation decimal", this.decimal);
        let percent = Math.trunc((this.decimal * 100) / 255);
        let v = min + Math.trunc((percent * (max - min)) / 100);
        return v;
    }
}

class JacksonId16Param {
    private decimal: number;
    
    constructor() {
        this.decimal = Math.trunc(Math.random() * 65535);
    }

    getHex(): string {
        return this.decimal.toString(16).padStart(4, '0');
    }

    toString(): string {
        return "" + this.decimal;
    }
}

export {JacksonByteParam, JacksonId16Param};