import Canvas from "../Canvas";
import { Effect } from "../Effect";
import { JacksonByteParam } from "../JacksonParam";
import { ActionType, MintAction } from "../MintAction";
import ArtParams from '../../../ArtParams.json';
import { JacksonUtils } from "../../utils";

class TileEffect extends Effect {
    x: JacksonByteParam = new JacksonByteParam();
    y: JacksonByteParam = new JacksonByteParam();
    width: JacksonByteParam = new JacksonByteParam();
    height: JacksonByteParam = new JacksonByteParam();

    constructor(args: {id: number, x: number, y: number, width: number, height: number}) {
        super(args.id);
        if (args) {
            if (args.x !== undefined) this.x.setDecimal(args.x);
            if (args.y !== undefined) this.y.setDecimal(args.y);
            if (args.width !== undefined) this.width.setDecimal(args.width);
            if (args.height !== undefined) this.height.setDecimal(args.height);
        }
    }

    getSummary(): string {
        return "Tile"
    }

    getActionType(): ActionType {
        return ActionType.Tile;
    }

    getAnimatedSVG() {
        let args = {
            x: this.x.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.y.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.width.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_WIDTH),
            height: this.height.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_HEIGHT),
        }
        return `<filter id='tile${this.id}' x='-140' y='-190' width='280' height='380'>` +
            `<feOffset dx='0' x='${args.x}' y='${args.y}' width='${args.width}' height='${args.height}' result='in'>` +
                `<animate attributeName='x' values='-140;${args.x}' ${MintAction.ANIMATION_TIMING} />` +
                `<animate attributeName='y' values='-190;${args.y}' ${MintAction.ANIMATION_TIMING} />` +
                `<animate attributeName='width' values='280;${args.width}' ${MintAction.ANIMATION_TIMING} />` +
                `<animate attributeName='height' values='380;${args.height}' ${MintAction.ANIMATION_TIMING} />` +
            `</feOffset>` +
            `<feTile in='in' x='-140' y='-190' width='280' height='380'/>` +
        `</filter><g filter='url(#tile${this.id})'><g>`
    }

    getSVG(): string {
        let args = {
            x: this.x.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.y.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.width.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_WIDTH),
            height: this.height.toValue(ArtParams.WINDOW_RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_HEIGHT),
        }
        return `<filter id='tile${this.id}' x='-140' y='-190' width='280' height='380'>` +
            `<feOffset dx='0' x='${args.x}' y='${args.y}' width='${args.width}' height='${args.height}' result='in'/>` +
            `<feTile in='in' x='-140' y='-190' width='280' height='380'/>` +
        `</filter><g filter='url(#tile${this.id})'><g>`
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_TILE.value);
        bytes += this.x.getHex();
        bytes += this.y.getHex();
        bytes += this.width.getHex();
        bytes += this.height.getHex();
        return bytes;
    }

    static fromByteString(byteString: string, hourId: number): TileEffect {
        return new TileEffect({
            id: hourId,
            x: parseInt(byteString.substring(2, 4), 16),
            y: parseInt(byteString.substring(4, 6), 16),
            width: parseInt(byteString.substring(6, 8), 16),
            height: parseInt(byteString.substring(8, 10), 16),
        });
    }
}

export {TileEffect}