import { JacksonUtils } from "../../utils";
import { Effect } from "../Effect";
import { JacksonByteParam } from "../JacksonParam";
import { ActionType, MintAction } from "../MintAction";
import ArtParams from '../../../ArtParams.json';

class ItalicizeEffect extends Effect {
    amount: JacksonByteParam = new JacksonByteParam(0);

    constructor(args: {id: number, amount: number}) {
        super(args.id);
        if (args && args.amount !== undefined) this.amount.setDecimal(args.amount);
        
    }

    getSummary() {
        return `Italicize ${this.amount.toValue(-50, 50)}`
    }

    getSVG() {
        return `<g transform='skewX(${this.amount.toValue(-50, 50)})'><g>`;
    }

    getAnimatedSVG(): string {
        return `<g transform="skewX(${this.amount.toValue(-50, 50)})" >
            <animateTransform attributeName="transform" type="skewX" from="0" to="${this.amount.toValue(-50, 50)}" ${MintAction.ANIMATION_TIMING} "/><g>`;
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_ITALICIZE.value);
        bytes += this.amount.getHex();
        return bytes;
    }

    getActionType(): ActionType {
        return ActionType.Italicize;
    }

    static fromByteString(byteString: string, effectId: number): ItalicizeEffect {
        return new ItalicizeEffect({
            id: effectId,
            amount: parseInt(byteString.substring(2, 4), 16),
        });
    }
}

export {ItalicizeEffect}