import { ActionType, MintAction } from './MintAction'
import ArtParams from '../../ArtParams.json';
import { Shape } from './Shape';
import { Effect } from './Effect';
import { Background } from './Background';
import Metadata from '../../tokenCache/metadata.json';

class Canvas {

    mints: MintAction[];

    static VIEW_X: number = -150;
    static VIEW_WIDTH: number = Math.abs(Canvas.VIEW_X * 2); // 300

    static VIEW_Y: number = -200;
    static VIEW_HEIGHT: number = Math.abs(Canvas.VIEW_Y * 2); // 400

    static PAINTABLE_MIN_X: number = ArtParams.PAINTABLE_MIN_X.value;
    static PAINTABLE_MAX_X: number = Canvas.PAINTABLE_MIN_X * -1;

    static PAINTABLE_MIN_Y: number = ArtParams.PAINTABLE_MIN_Y.value;
    static PAINTABLE_MAX_Y: number = Canvas.PAINTABLE_MIN_Y * -1;

    static PAINTABLE_WIDTH: number = this.PAINTABLE_MIN_X * -2;
    static PAINTABLE_HEIGHT: number = this.PAINTABLE_MIN_Y * -2;

    static SVG_STUB: string = `
    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink= "http://www.w3.org/1999/xlink" viewBox="${Canvas.VIEW_X} ${Canvas.VIEW_Y} ${Canvas.VIEW_WIDTH} ${Canvas.VIEW_HEIGHT}">` +
        `<rect x="${Canvas.VIEW_X}" y="${Canvas.VIEW_Y}" width="${Canvas.VIEW_WIDTH}" height="${Canvas.VIEW_HEIGHT}" fill="`

    static SVG_STUB_2: string = `" />            
        <clipPath id="canvas">
            <rect x="${Canvas.PAINTABLE_MIN_X}" y="${Canvas.PAINTABLE_MIN_Y}" width="${Canvas.PAINTABLE_WIDTH}" height="${Canvas.PAINTABLE_HEIGHT}"/>
        </clipPath>
        <g id="thecanvas" style="clip-path:url(#canvas)">`

    static SVG_END_STUB: string = `</g></svg>`

    constructor() {
        this.mints = [];
    }

    // newest mints are at end of list
    addMint(mint: MintAction) {
        this.mints.push(mint);
        return this;
    }

    // setMintsFromBytes32Array(bytes32Array: string[]) {
    //     this.mints = bytes32Array.map(bytes32 => createMintActionFromByteString(bytes32.substring(2)));
    // }

    // if it's a shape, simply prepend the shape
    // if it's an effect, prepend closing groups, but save the effect to add later (earlier in the SVG file)
    prependMintSVG(svg: string, mint: MintAction, effects: Effect[], animated: boolean): string {
        if (mint instanceof Shape) {
            return (animated ? mint.getAnimatedSVG() : mint.getSVG()) + svg;
        }
        else if (mint instanceof Effect) {
            effects.push(mint);
            return `</g></g>` + svg;
        }
        return "WTF NOT RECOGNIZED MINT TYPE";
    }

    getSVG(animateLast: boolean, previewMint?: MintAction): string {
        var svg = "";
        var effectsQueue: Effect[] = [];
        if (typeof previewMint !== 'undefined') {
            svg = this.prependMintSVG(svg, previewMint, effectsQueue, animateLast);
        }

        // go through mints from newest to oldest
        for (var i = this.mints.length - 1; i >= 0; i--) {
            var animate = typeof previewMint == 'undefined' && i == this.mints.length - 1 && animateLast;
            svg = this.prependMintSVG(svg, this.mints[i], effectsQueue, animate);
        }

        // pop off the queue
        for (var i = effectsQueue.length - 1; i >= 0; i--) {
            var animate =
                i == 0
                && animateLast
                && (effectsQueue[i] == this.mints[this.mints.length - 1] || effectsQueue[i] == previewMint)
            svg = (animate ? effectsQueue[i].getAnimatedSVG() : effectsQueue[i].getSVG()) + svg;
        }

        svg = Canvas.SVG_STUB + "#FFFFFF" + Canvas.SVG_STUB_2 + svg + Canvas.SVG_END_STUB;
        return svg;

    }
}

// if it's a shape, simply prepend the shape
// if it's an effect, prepend closing groups, but save the effect to add later (earlier in the SVG file)
const prependMintSVG = (svg: string, mint: MintAction, effects: Effect[], animated: boolean): string => {
    if (mint instanceof Shape) {
        return (animated ? mint.getAnimatedSVG() : mint.getSVG()) + svg;
    }
    else if (mint instanceof Effect) {
        effects.push(mint);
        return `</g></g>` + svg;
    }
    else if (mint instanceof Background) {
        return svg;
    }
    throw new Error("WTF NOT RECOGNIZED MINT TYPE " + mint);
}


const prependCheckpointSVG = (svg:string, checkPointIndex:number): string => {
    const checkpointSVG = `<image x="-150" y="-200" width="300" height="400" xlink:href="/tokenPNGs/token${checkPointIndex}hires.png" />`;
    return checkpointSVG + svg;
}

const getSVGWithCheckpoint = (mints: MintAction[], animateLast: boolean, index: number): string => {
    var svg = "";
    if (mints.length === 0 || (index && index < 0)) return svg;
    var effectsQueue: Effect[] = [];
    var bgColor = "bacon";
    var checkPointReached = false;

    // go through mints from newest to oldest
    var startIndex = typeof index === 'undefined' ? mints.length - 1 : index;
    for (var i = startIndex; i >= 0; i--) {
        
        var animate = animateLast && i === startIndex;
        if (!checkPointReached && Metadata.checkpoints.some((e) => (e === i && i < index))) {
            svg = prependCheckpointSVG(svg, i);
            checkPointReached = true;
        }
        if (mints[i] instanceof Background && bgColor === "bacon") {
            bgColor = (mints[i] as Background).fill.toString();
        
        }
        else if (!checkPointReached) {
            svg = prependMintSVG(svg, mints[i], effectsQueue, animate);
        }   
    }

    

    // pop off the queue
    for (var i = effectsQueue.length - 1; i >= 0; i--) {
        var animate = animateLast && i === 0 && effectsQueue[i] === mints[startIndex]
        svg = (animate ? effectsQueue[i].getAnimatedSVG() : effectsQueue[i].getSVG()) + svg;
    }
    if (bgColor === "bacon") bgColor = "white";
    svg = Canvas.SVG_STUB + bgColor + Canvas.SVG_STUB_2 + svg + Canvas.SVG_END_STUB;
    return svg;
}

const getSVG = (mints: MintAction[], animateLast: boolean, index?: number): string => {
    var svg = "";
    if (mints.length === 0 || (index && index < 0)) return svg;
    var effectsQueue: Effect[] = [];
    var bgColor = "bacon";

    // go through mints from newest to oldest
    var startIndex = typeof index === 'undefined' ? mints.length - 1 : index;
    for (var i = startIndex; i >= 0; i--) {
        var animate = animateLast && i === startIndex;
        if (mints[i] instanceof Background && bgColor === "bacon") {
            bgColor = (mints[i] as Background).fill.toString();
        }
        else {
            svg = prependMintSVG(svg, mints[i], effectsQueue, animate);
        }   
    }

    // pop off the queue
    for (var i = effectsQueue.length - 1; i >= 0; i--) {
        var animate = animateLast && i === 0 && effectsQueue[i] === mints[startIndex]
        svg = (animate ? effectsQueue[i].getAnimatedSVG() : effectsQueue[i].getSVG()) + svg;
    }
    if (bgColor === "bacon") bgColor = "white";
    svg = Canvas.SVG_STUB + bgColor + Canvas.SVG_STUB_2 + svg + Canvas.SVG_END_STUB;
    return svg;
}



// --------------- TAG STUFF FOR MARKETING PAGE ----------------
type Tag = {
    text: string,
    mint: number
}

const svgToTags = (svg: string, mint: number): Tag[] => {
    var splits = svg.split(">");
    var tags: Tag[] = [];
    splits.forEach(split => {
        if (split.length > 0) {
            tags.push({ text: split + ">", mint: mint })
        }
    })
    return tags;
}

const prependTags = (tags: Tag[], mint: MintAction, effects: Effect[], mintN: number): Tag[] => {
    if (mint instanceof Shape) {
        return [...svgToTags(mint.getSVG(), mintN), ...tags];
    }
    else if (mint instanceof Effect) {
        effects.push(mint);
        return [{ text: "</g>", mint: mintN }, { text: "</g>", mint: mintN }, ...tags]
    }
    return [];
}

const findAction = (mint: MintAction, mints: MintAction[]): number => {
    for (var i = 0; i < mints.length; i++) {
        if (mints[i] == mint) {
            return i;
        }
    }
    return 0;
}

const getTags = (mints: MintAction[]): Tag[] => {
    var tags: Tag[] = [];
    var effectsQueue: Effect[] = [];
    for (var i = mints.length - 1; i >= 0; i--) {

        tags = prependTags(tags, mints[i], effectsQueue, i);
    }
    for (var i = effectsQueue.length - 1; i >= 0; i--) {
        tags = [...svgToTags(effectsQueue[i].getSVG(), findAction(effectsQueue[i], mints)), ...tags];
    }
    return tags;
}


export default Canvas;
export type { Tag };
export { getSVG, getTags, getSVGWithCheckpoint };
