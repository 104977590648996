import { JacksonColor, JacksonUtils } from "../utils";
import { ActionType, MintAction } from "./MintAction";
import ArtParams from '../../ArtParams.json';

class Background extends MintAction {
    fill: JacksonColor = new JacksonColor();

    constructor(args: {fill: string}) {
        super();
        if (args) {
            if (args.fill !== undefined) {
                this.fill = new JacksonColor({ hexCode: args.fill });
            }
        }
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_BACKGROUND.value);
        bytes += this.fill.hexCode.substring(1);
        return bytes;
    }

    getSVG(): string {
        return "";
    }

    getAnimatedSVG(): string {
        return "";
    }

    getSummary(): string {
        return "Set Background to " + this.fill.hexCode;
    }

    getActionType(): ActionType {
        return ActionType.Background;
    }
    
    static fromByteString(byteString: string): Background {
        return new Background({fill: "#" + byteString.substring(2, 8)});
    }
    
}

export {Background}