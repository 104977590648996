import ReactPlayer from "react-player"
import { AuctionThumbnail } from "./AuctionThumbnail"
import { ExampleAuction } from "./ExampleAuction"

const Essay = () => {
    return <div className="container mx-auto w-full text-neutral-900 font-noto pt-18">
        <div className="w-5/6 sm:w-[700px] mx-auto flex flex-col items-center">
            <Heading text={"About"} />
            <div>
                TheHours was an experiment in collaborative art on the blockchain. It began on March 10th and ran for 11 days, spanning 260 hours and over 100 different participants. Each hour, a new auction started with a set of possible "actions", like adding a rectangle, or rotating the canvas. Participants created bids with both a customized action and a price, and the winning bid's action was added to the artwork, creating a new piece from the actions accumulated up to that point.
            </div>
            <div className="h-12" />
            
            <ExampleAuction />
            <Caption text="An example auction. Adjust the sliders to create a bid." />
            <Heading text={"Motivation"} />

            <div>
                I wondered if art that evolves over time and is created from a community could offer a different kind of emergence than other generative art collections, whose most interesting creations emerge from random seeds generated from block timestamps or wallet addresses, rather than from the chaos human collaboration. A collaborative, evolving work of art would be uniquely suited to the blockchain, because decentralized decision making is a primary feature of blockchain technology. Because it changes over time, it manifests history and the passage of time, imbuing it with an authenticity that is different from an artwork crafted intentionally with a strong point-of-view, generative or not. <br/><br/>
            </div>
            <div className={"relative pt-[56.25%] z-0 transition-all w-full mx-auto"}>
                        <ReactPlayer
                            url="https://vimeo.com/798890876" // TRAILER
                            width="100%"
                            height="100%"
                            playing={false}
                            style={{ position: "absolute", top: 0, left: 0 }}
                            controls
                            muted={false}
                        />
                    </div>

            <Heading text={"Risks & Questions"} />

            <div>
                These feelings lead me to create a generative art collection that intentionally mints very slowly, using an auction as a decentralized decision making protocol. <b>While an auction mechanic is well suited to blockchain technology, an NFT collection that mints slowly is not well suited to blockchain culture.</b><br/><br/>
            </div>
            <div className="w-3/4 italic text-neutral-700 text-center">
                Question: is a collection that mints slowly compatible with NFT culture?<br/><br/>
            </div>
            <div>
             The majority of NFT collections operate on a hype-cycle that peaks at the launch of the collection, and lasts for a day at most. Scarcity, rarity, and FOMO drive prices up, and only the most popular projects avoid immediate obsolescence when the mint hype ends. Creating a collection that mints slowly was a risk--a question to the community of whether or not a collection could create create value over time built from the engagement of collectors themselves, rather than the promise of the artists future clout.<br/><br/>
            </div>
            
            <div className="w-3/4 italic text-neutral-700 text-center">
                Question: would you value art over token value? <br/><br/>
            </div>

            <div>
                The collection also frequently asked collectors to balance art and tokenomics. In typical NFT collections, token value is largely driven by how rare it's "attributes" are. (attributes are simply bits of text that describe the piece in a more structured way, such as what color pallette was used or texture category). With TheHours, rare attributes corresponded to the different available actions for the given auction--painting a rectangle was available 100% of the time, while effects like Tile and Shear were available 2.5% of the time. If the collector was playing only to tokenomics, they would always use the rarest effect, regardless of what they thought the piece needed from an aesthetic perspective.<br/><br/>

                The most extreme form of this tension was the Finishing Touch action, which was the rarest action, whose effect was to end the collection. Using a Finishing Touch would mint the winner the rarest token, and also increase the value of any previously minted tokens by limiting supply. However, on two separate occasions (<HourLink hour={177} />, <HourLink hour={181} />), the Finishing Touch was outbid by collectors to continue the collection.
            </div>

            <div className="pt-8 w-full sm:w-[500px]">
                <AuctionThumbnail hour={177} />
            </div>
            <Caption text="Peycke.eth outbidding 0x3689c.eth's Finishing Touch bids (in red) to keep the collection going. " />

            <Heading text={"Collaborative Art on the Blockchain"} />
            <div>
                When I envisioned this project, I was excited by the interplay that would happen between auctions. Each bid is a chance to respond to the previous auction, and guide the collection in a new direction. However, I was surprised by how much of the collaboration happened <b>within a single auction,</b> when participants saw each other's bids and riffed off of them, or battled against them.
            </div>

            <div className="pt-8 w-full sm:w-[500px]">
                <AuctionThumbnail hour={65} />
            </div>
            <Caption text="dschul.eth began Hour#65 with a green circle, but 0x3e6a20's brown 'mouth' bid sets off a stream of new mouth-related bids." />

            <div className="pt-8">
                Throughout TheHours mint period, I hosted a series of live streams with other generative artists and NFT communities to emphasize the live nature of the project, and foster more real-time collaboration. In this clip, featuring the CathartesNFT team, one of their bids was outbid by an extremely similar bid, leaving us to wonder what message the new bidder was trying to send. <br/><br/>
            </div>
            <div className="py-4 w-full">
                <iframe width="100%" height="315" src="https://www.youtube.com/embed/xRBw2LqrYSA" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
            </div>
            <Caption text="The Cathartes team is puzzled by a new bid on Hour#40" />

            <Heading text={"Reflections"} />
            <div>
                TheHours is my proudest achievement in generative art because it was an experiment that lead to unexpected results. I was surprised by how bidding wars translated to collaboration, by how many community members valued art over token value, and by the genuinely incredible pieces that were created. <br/><br/>

                I'm inspired to continue exploring different mechanics for interactivity and collaboration. For example, because the majority of collaboration happened in bidding wars towards the end of each Hour, if I could re-do TheHours, I'd call it TheMinutes and have each auction be much shorter. That would condense the periods of interactivity. Another variation would be to have the auction period increase exponentially and never end (starting with a few minutes and eventually getting to days and then years). <br/><br/>

                However, I'm also eager to produce a collection with more of my own voice and taste. Finding a balance between unique interactions / mechanics, and plain-old expressive visuals, is a challenge I'm excited to tackle in future collections. Stay tuned at <a className="underline" href="https://twitter.com/lxrogers">twitter.com/lxrogers</a> for more updates.
            </div>
        </div>
    </div>
}

const HourLink = ({hour}:{hour: number}) => {
    return <a className="underline" target="_blank" href={"/viewall/" + hour}>{"Hour#" + hour}</a>
}
const Caption = ({text}: {text: string}) => {
    return <div className="w-5/6 italic text-neutral-700 text-center text-sm py-2">{text}</div>
}

const Heading = ({text}: {text: string}) => {
    return <div className="w-full text-4xl font-bold font-catamaran mt-12">{text}</div>
}

export {Essay}