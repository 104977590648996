import { ethers } from "ethers";
import { useEffect, useState } from "react";

import { useContract, useContractEvent, useProvider } from "wagmi";
import { TheHoursAuctionHouseABI } from "../abis/TheHoursAuctionHouse";
import { MintAction } from "../components/Models/MintAction";
import { createMintActionFromByteString } from "../components/Models/MintActionFactory";
import { AUCTION_CONTRACT_ADDRESS } from "./ChainEnv";

type Bid = {
  jacksonId: ethers.BigNumber;
  sender: string;
  value: ethers.BigNumber;
  details: string;
  blockNumber: number;
  transactionIndex: number;
  transactionHash: string;
  mintAction: MintAction;
}

const formatBid = (evt: any): Bid => {
  if (!evt.args) {
    throw new Error("Event args are null");
  }

  const [jacksonId, sender, value, _, details] = evt.args;
  
  return {
    jacksonId,
    sender,
    value,
    details,
    blockNumber: evt.blockNumber,
    transactionIndex: evt.transactionIndex,
    transactionHash: evt.transactionHash,
    mintAction: createMintActionFromByteString(details.slice(2), parseInt(ethers.utils.formatUnits(jacksonId, 0)))
  }
}

const useAllBidsForJackson = (jacksonId: ethers.BigNumber, onInitialFetch?: (bids: Bid[]) => void) => {
  const provider = useProvider();
  const contract = useContract({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    signerOrProvider: provider,
  })

  const [bids, setBids] = useState<Bid[]>([]);
  // Bootstraps the bids that happened to this point
  useEffect(() => {
    if (!contract) return;

    const bidFilter = contract.filters.AuctionBid(ethers.BigNumber.from(jacksonId), null, null, null, null);
    contract.queryFilter(bidFilter, 16799511, "latest").then((events: ethers.Event[]) => {
      const bids = events.map(evt => formatBid(evt));
      setBids(bids);
      onInitialFetch && onInitialFetch(bids)
    })
    
    
  }, [jacksonId.toString()]);

  // Listens for new bids
  useContractEvent({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    eventName: 'AuctionBid',
    // add filter for the jacksonId
    listener: (jacksonId, sender, value, extended, mintDetails, evt: ethers.Event) => {
      const newBid = formatBid(evt);
      if (!bids.some(bid => bid.transactionHash === newBid.transactionHash)) {
        setBids([...bids, newBid]);
      }
    }
  })

  return bids;
}

export {
  useAllBidsForJackson,
  formatBid,
  type Bid
}