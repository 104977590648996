import { Availability, AvailabilityByAction } from "../../lib/useAvailableActions";
import { ActionType, ActionTypeToName } from "../Models/MintAction";

function ActionPicker({
    selectedAction,
    possibleActions,
    onChange,
    availabilityByAction,
}: {
    selectedAction: ActionType,
    possibleActions: ActionType[],
    onChange: (event: any) => void,
    availabilityByAction: AvailabilityByAction
}) {
    
    const availableActions = possibleActions.filter(action => {
        return availabilityByAction[action]?.isAvailableToUser || availabilityByAction[action]?.isAvailableToAllowlistOnly;
    })
    
    const finisingTouchType = 255;//useFinishingTouchType();

    return <div className="flex flex-row items-center gap-1 flex-wrap">
        {
            availableActions.map((action) => {
                return (
                    <ActionOption
                        key={`ActionOption${action}`}
                        isSelected={action === selectedAction}
                        name={ActionTypeToName[action]}
                        availability={availabilityByAction[action]}
                        onClick={() => onChange(action)}
                    />
                )
            })
        }

        <div className="group relative cursor-pointer">
            <div className="group-hover:fill-black fill-neutral-500 rounded-full transition-all">
                {infoIcon}
            </div>

            <div className="absolute opacity-0 group-hover:opacity-100 right-[120%] -top-[5px] text-[12px] leading-4 bg-black/80 text-white z-50 p-2 w-[150px] pointer-events-none transition-all rounded">
                Each auction, different actions are available based on their rarity.
            </div>
        </div>
    </div>
}



const infoIcon = <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M9.167 14.083h1.666V9H9.167ZM10 7.604q.354 0 .604-.25t.25-.604q0-.354-.25-.604T10 5.896q-.354 0-.604.25t-.25.604q0 .354.25.604t.604.25Zm0 10.563q-1.688 0-3.177-.636-1.49-.635-2.604-1.75-1.115-1.114-1.75-2.604-.636-1.489-.636-3.177 0-1.708.636-3.187.635-1.48 1.75-2.594 1.114-1.115 2.604-1.75Q8.312 1.833 10 1.833q1.708 0 3.188.636 1.479.635 2.593 1.75 1.115 1.114 1.75 2.604.636 1.489.636 3.177t-.636 3.177q-.635 1.49-1.75 2.604-1.114 1.115-2.604 1.75-1.489.636-3.177.636Zm0-1.729q2.688 0 4.562-1.876 1.876-1.874 1.876-4.562t-1.876-4.562Q12.688 3.562 10 3.562T5.438 5.438Q3.562 7.312 3.562 10t1.876 4.562Q7.312 16.438 10 16.438ZM10 10Z" /></svg>

const lockIcon = <div className="absolute -top-2 -right-1.5">
        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 96 960 960" width="14"><path d="M279.717 981q-46.63 0-79.673-33.194Q167 914.612 167 868V514q0-46.612 33.194-79.806Q233.387 401 280 401v-64.791q0-85.389 57.976-144.299Q395.952 133 480.226 133t142.024 58.91Q680 250.82 680 336.209V401q46.612 0 79.806 33.194Q793 467.388 793 514v354q0 46.612-33.206 79.806Q726.589 981 679.96 981H279.717Zm200.495-221q28.551 0 48.669-20.331Q549 719.338 549 690.788q0-28.551-20.331-48.669Q508.338 622 479.788 622q-28.551 0-48.669 20.331Q411 662.662 411 691.212q0 28.551 20.331 48.669Q451.662 760 480.212 760ZM393 401h174v-65.154q0-37.179-24.882-63.513-24.883-26.333-62-26.333Q443 246 418 272.25T393 336v65Z"/></svg>
    </div>

const lockOpenIcon = <div className="absolute -top-2 -right-1.5 fill-emerald-600">
        <svg xmlns="http://www.w3.org/2000/svg" height="14" viewBox="0 96 960 960" width="14"><path d="m426 748 261-261-84-86-177 176-74-73-85 86 159 158Zm54 258q-88.64 0-167.467-33.104-78.828-33.103-137.577-91.852-58.749-58.749-91.852-137.577Q50 664.64 50 576q0-89.896 33.162-167.949 33.163-78.053 92.013-136.968 58.85-58.916 137.576-91.999Q391.476 146 480 146q89.886 0 167.931 33.084 78.044 33.083 136.973 92.012 58.929 58.929 92.012 137Q910 486.167 910 576.083q0 88.917-33.084 167.404-33.083 78.488-91.999 137.338-58.915 58.85-136.968 92.012Q569.896 1006 480 1006Z"/></svg>
    </div>

const stopIcon = <div className="fill-red-700 -ml-2 mr-1">
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M7.583 12.417h4.855V7.562H7.583Zm2.438 4.395q-1.417 0-2.656-.531-1.24-.531-2.177-1.469-.938-.937-1.469-2.177-.531-1.239-.531-2.635 0-1.417.531-2.646.531-1.229 1.469-2.166.937-.938 2.177-1.469Q8.604 3.188 10 3.188q1.417 0 2.646.531 1.229.531 2.166 1.469.938.937 1.469 2.166.531 1.229.531 2.625 0 1.417-.531 2.656-.531 1.24-1.469 2.177-.937.938-2.166 1.469-1.229.531-2.625.531Z"/></svg>
</div>

const ActionOption = ({
    availability = {
        isAvailableToUser: false,
        isAvailableToAll: false,
        isAvailableToAllowlistOnly: false,
        isFinishingTouch: false,
    },
    name,
    isSelected,
    onClick,
}: {
    availability: Availability,
    name: string,
    isSelected: boolean;
    onClick: Function;
}) => {
    
    const {
        isAvailableToUser,
        isAvailableToAll,
        isAvailableToAllowlistOnly,
        isFinishingTouch,
    } = availability;

    const borderColorUnselected = isFinishingTouch ? "border-red-600" : "border-neutral-700";
    const borderColorSelected = isFinishingTouch ? "border-red-900" : "border-neutral-900";
    const bgColor = isFinishingTouch ? "bg-red-600" : "bg-black";

    return (
        <button
            
            className={`
                group relative text-xs font-inconsolata font-bold tracking-tight rounded-xl m-px pt-0.5 pb-0.5 p-2 border-2 cursor-pointer transition-all
                flex flex-row items-center  
                ${isSelected 
                    ? bgColor + " text-white " + borderColorSelected
                    : "hover:bg-neutral-400 text-neutral-700 fill-neutral-700 " + borderColorUnselected}
            `}
            onClick={() => onClick()}>
            {!isAvailableToUser && isAvailableToAllowlistOnly && lockIcon}
            {isAvailableToUser && isAvailableToAllowlistOnly && lockOpenIcon}
            {isFinishingTouch && stopIcon}
            {name}
        
            
            
        </button>
    )
}

export {ActionPicker}