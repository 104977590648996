import { useEffect, useRef, useState } from "react"
import { FramedCanvas } from "../CanvasView"
import { MintSummary } from "../Hero/RecentBids"
import { getSVGWithCheckpoint } from "../Models/Canvas"
import { ActionType, ActionTypeToName, MintAction } from "../Models/MintAction"
import { PossibleActionTypes } from "../Models/MintAction"
import { expandMore, expandLess, leftArrow, rightArrow } from "../Website/Icons"

function GalleryItem(
        props: {mintAction: MintAction, svg: string, index: number, highlighted:boolean, onClick: (index: number) => void}) 
    {

    return <div key={props.index} className="group w-1/2 sm:w-1/5 px-4 py-6 relative cursor-pointer" 
                onClick={()=> {props.onClick(props.index)}}>
        
        <div className={"absolute w-full h-full top-0 left-0 transition-all bg-neutral-300 group-hover:opacity-100 " + 
                        (props.highlighted ? "opacity-80" : "opacity-0")} />
        
        <div className="flex flex-col relative items-left">    
            <div  className="w-full pr-8">
                <FramedCanvas SVG={props.svg} />
            </div>
        
            <div className="w-full font-catamaran opacity-60 ml-7 pb-2">
                <div className=" font-semibold text-sm">
                    Hour#{props.index}
                </div>
                <div className=" font-normal font-inconsolata text-[10px] -mt-1">
                    <MintSummary mintAction={props.mintAction}/>
                </div>
            </div>
        </div>
    </div>
}

function ViewAllGallery(
    {mintActions: mintActions, viewIndex, setViewIndex}: 
    {mintActions: MintAction[], viewIndex: number, setViewIndex: (index: number) => void}
) {    
    const ITEMS_PER_PAGE_SMALL = 5;
    const ITEMS_PER_PAGE_LARGE = 10;
    const [sortOrder, setSortOrder] = useState("oldest");
    const [filterType, setFilterType] = useState<ActionType | null>(null);
    const [page, setPage] = useState<number>(0);
    const [itemsPerPage, setItemsPerPage] = useState(ITEMS_PER_PAGE_SMALL);
    
    const getGalleryItems = () => {
        var allItems = [];
        
        for (var i = 0; i <= mintActions.length - 1; i++) {
            if (filterType === null || (mintActions[i].getActionType() === filterType)) {
                allItems.push(
                    <GalleryItem 
                        key={i}
                        mintAction={mintActions[i]} 
                        svg={getSVGWithCheckpoint(mintActions, false, i)} 
                        index={i} 
                        highlighted={i === viewIndex}
                        onClick={setViewIndex}/>);
            }
        }
        if (sortOrder === "newest") {
            allItems = allItems.reverse();
        }
        return allItems;
    }

    useEffect(() => {
        setPage(0);
    }, [filterType])

    const gallery = getGalleryItems();
    const galleryPage = gallery.slice(itemsPerPage * page, itemsPerPage * (page + 1));

    return <div className="w-full text-black relative pt-8">
        {itemsPerPage === ITEMS_PER_PAGE_LARGE && 
            <ViewOptions sortOrder={sortOrder} setSortOrder={setSortOrder} filterType={filterType} setFilterType={setFilterType} />
        }
        
        <div className="container mx-auto w-full flex flex-row items-center justify-between">
            <div className="">
                <PageLeft currentPage={page} setCurrentPage={setPage}/>
            </div>
            <div className="flex flex-row flex-wrap items-center">
                {galleryPage}
            </div>
            <div className="">
                <PageRight currentPage={page} setCurrentPage={setPage} maxPage={Math.trunc(gallery.length / itemsPerPage) - 1}/>
            </div>
        </div>
        {itemsPerPage === ITEMS_PER_PAGE_LARGE && 
            <PageNav page={page} setPage={setPage} maxPage={Math.ceil(gallery.length / itemsPerPage)}/>
            
        }
        
        <ExpandGalleryButton 
            expanded={itemsPerPage === ITEMS_PER_PAGE_LARGE} 
            onToggle={() => {setItemsPerPage(itemsPerPage === ITEMS_PER_PAGE_LARGE ? ITEMS_PER_PAGE_SMALL : ITEMS_PER_PAGE_LARGE)}}/>

    </div>
}

const ExpandGalleryButton = (
    {
        expanded,
        onToggle
    } : {
        expanded: boolean,
        onToggle: () => void
    }) => {

        return <div className="container mx-auto flex flex-col items-center w-full">
            <div 
                className={"flex items-center group cursor-pointer " + (expanded ? "flex-col-reverse" : "flex-col")}
                onClick={onToggle}>
                <div className={"font-inconsolata text-neutral-600 " + 
                                "group-hover:text-black transition-all"}>
                    
                    {expanded ? "Show Less" : "View More & Filter"}</div>
                <div className="-mt-2 -mb-2 fill-neutral-600 group-hover:fill-black transition-all">
                    {expanded ? expandLess : expandMore}
                </div>
            </div>
        </div>
    }


const PageLeft = (
    {currentPage, setCurrentPage}:
    {currentPage: number, setCurrentPage: (page:number) => void
}) => {
    const enabled = currentPage > 0;
    return <button className={`p-1 fill-neutral-600 border-2 rounded-full border-black/0 hover:border-black hover:fill-neutral-800 transition-all cursor-pointer 
                                ${enabled ? "opacity-100" : "opacity-0"}`}
                onClick={() => {setCurrentPage(currentPage - 1)}}
                disabled={!enabled}>
                {leftArrow}
            </button>
}

const PageRight = (
    {currentPage, setCurrentPage, maxPage}:
    {currentPage: number, setCurrentPage: (page:number) => void, maxPage: number
}) => {
    const enabled = currentPage < maxPage;
    return <button className={`p-1 fill-neutral-600 border-2 rounded-full border-black/0 hover:border-black hover:fill-neutral-800 transition-all cursor-pointer
                ${enabled ? "opacity-100" : "opacity-0"}`}
                onClick={() => {setCurrentPage(currentPage + 1)}}
                disabled={!enabled}>
                {rightArrow}
            </button>
        
}

const ViewOptions = (
    {
        sortOrder, 
        setSortOrder, 
        filterType, 
        setFilterType
    }: {
        sortOrder: string, 
        setSortOrder: (sortOrder: string) => void,
        filterType: ActionType | null, 
        setFilterType: (filterType: ActionType | null) => void
    }) => {
        return <div className="container mx-auto flex flex-col sm:flex-row px-10 pt-8 space-x-6 items-center">
            <div className="flex flex-row items-center">
                <div className="font-inconsolata text-sm font-bold"> Sort Order: </div>
                <select 
                    onChange={(e) => {setSortOrder(e.target.value)}}
                    value={sortOrder}
                    className="m-2 text-xs p-1 bg-white/70 rounded-md" >
                    <option value="oldest">Oldest First</option>
                    <option value="newest">Newest First</option>
                </select>
            </div>
            <div className="flex flex-row items-center">
                <div className="font-inconsolata text-sm font-bold"> Action Filter: </div>
                <select 
                    onChange={(e) => {
                        setFilterType(e.target.value !== 'bacon' ? parseInt(e.target.value) as ActionType : null)
                    }}
                    className="m-2 text-xs p-1 bg-white/70 rounded-md" >
                        { [...PossibleActionTypes, null].map( (actionType) => {
                            if (actionType !== null) {
                                return <option key={actionType} value={actionType}>{ActionTypeToName[actionType]}</option>
                            }
                            else {
                                return <option key={'bacon'} value={'bacon'}>None</option>
                            }
                        })
                    }
                </select>
            </div>
        </div>

    }


function PageNav({page, setPage, maxPage}: {page: number, setPage: (page: number) => void, maxPage: number}) {
    const getPageNumbers = () => { 
        var pageNumbers = [];
        for (var i = 0; i < maxPage; i++) {
            pageNumbers.push(
                <div key={i} className={i === page ? "font-bold" : " underline cursor-pointer"} 
                    onClick={(e) => { setPage(parseInt((e.target as HTMLElement).innerHTML))}}>
                        {i}</div>
            );
        }
        return pageNumbers;
    }

    return <div className="container mx-auto flex flex-row flex-wrap items-center justify-center space-x-4 pb-12 text-neutral-600">
        {getPageNumbers()}
    </div>
}

export {ViewAllGallery}