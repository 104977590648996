import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { Bid, useAllBidsForJackson } from '../../lib/useAllBidsForJackson';
import { useShowMore } from '../../lib/useShowMore';
import { BidderExpando } from '../Bidder/Bidder';
import { Background } from '../Models/Background';
import { MintAction } from '../Models/MintAction';
import { Circle, Rectangle } from '../Models/Shape';
import { useEnsAvatar, useEnsName } from 'wagmi'
import auctionBidsCache from "../../tokenCache/auctionBidsCache.json";
import {formatBid } from '../../lib/useAllBidsForJackson';

const eyeIcon = <div className="w-[20px] h-[20px]">
    <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path fill="0xEEE" d="M10 13.5q1.458 0 2.479-1.021Q13.5 11.458 13.5 10q0-1.458-1.021-2.479Q11.458 6.5 10 6.5q-1.458 0-2.479 1.021Q6.5 8.542 6.5 10q0 1.458 1.021 2.479Q8.542 13.5 10 13.5Zm0-1.604q-.792 0-1.344-.552T8.104 10q0-.792.552-1.344T10 8.104q.792 0 1.344.552T11.896 10q0 .792-.552 1.344T10 11.896Zm0 4.25q-3.021 0-5.5-1.677T.854 10Q2.021 7.208 4.5 5.531 6.979 3.854 10 3.854t5.5 1.677Q17.979 7.208 19.146 10q-1.167 2.792-3.646 4.469-2.479 1.677-5.5 1.677ZM10 10Zm0 4.5q2.333 0 4.302-1.208 1.969-1.209 3.031-3.292-1.062-2.083-3.031-3.292Q12.333 5.5 10 5.5q-2.333 0-4.302 1.208Q3.729 7.917 2.667 10q1.062 2.083 3.031 3.292Q7.667 14.5 10 14.5Z" /></svg>
</div>

const winnerIcon = <div className="w-[20px] h-[20px] fill-green-900">
    <svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 96 960 960" width="20"><path d="M395 778.652 218.348 601l57.891-57.891L395 660.869l288.761-287.76L741.652 432 395 778.652Z" /></svg>
</div>

const RecentBids = ({
    selectedBid,
    tokenId,
    isExpanded,
    onToggle,
    onSelect,
    auctionCompleted,
    historical,
}: {
    selectedBid: Bid | undefined,
    tokenId: BigNumber,
    isExpanded: boolean,
    onToggle: () => void
    onSelect: (bid: Bid) => void
    auctionCompleted: boolean
    historical: boolean
}) => {

    //const allBidsForJackson = useAllBidsForJackson(tokenId, (bids: Bid[]) => onSelect(bids[bids.length - 1]));
    const bidEvents = auctionBidsCache[tokenId.toNumber()].bids
    const allBidsForJackson: Bid[] = bidEvents.map(evt => formatBid(evt) );
    const reversed = allBidsForJackson.slice().reverse();
    const [heightStyle, setHeightStyle] = useState({ height: 0 })

    const {
        items: bidsToDisplay,
        onToggle: onToggleShowMore,
        shouldDisplayShowAll,
        shouldDisplayShowLess,
        numMoreToShow,
    } = useShowMore(reversed, 5);

    const calculateHeightStyle = () => {
        if (isExpanded && shouldDisplayShowLess) {
            return { height: 300 }
        }
        else {
            var panel = document.getElementById("bids");
            var clientHeight = panel != null ? panel.clientHeight : 0;
            var panelHeight = !isExpanded ? 0 : clientHeight;

            return { height: panelHeight }
        }
    }

    const getBids = () => {
        var bids = [];
        for (var i = 0; i < bidsToDisplay.length; i++) {
            const bid = bidsToDisplay[i];
            const selected = selectedBid != null && selectedBid.transactionHash === bid.transactionHash;
            const isWinner = auctionCompleted && i === 0;
            bids.push(<RecentBid bid={bid} key={bid.transactionHash} onClick={() => onSelect(bid)} isSelected={selected} isWinner={isWinner} />)


        }
        return bids;
    }

    useEffect(() => {
        setHeightStyle(calculateHeightStyle());
    }, [allBidsForJackson, isExpanded, shouldDisplayShowLess])

    return (
        <div className="flex flex-col overflow-hidden mt-2">
            <div className="flex flex-row w-full px-2 pt-1 font-catamaran font-bold text-lg justify-between cursor-pointer hover:bg-neutral-300 rounded-md"
                onClick={onToggle}>
                <div>
                    {historical ? "Bid History" : "Recent Bids" }
                </div>
                <BidderExpando expanded={isExpanded} />
            </div>

            <div style={heightStyle} className="transition-all duration-300 overflow-y-auto">
                <div id="bids">
                    {getBids()}
                    {shouldDisplayShowAll ?
                        <div className="w-full text-center text-black text-xs font-inconsolata underline cursor-pointer"
                            onClick={() => onToggleShowMore()}>
                            See {numMoreToShow} more
                        </div>
                        : <div />
                    }
                    {shouldDisplayShowLess ?
                        <div className="w-full text-center text-black text-xs font-inconsolata underline cursor-pointer"
                            onClick={() => onToggleShowMore()}>
                            Show fewer
                        </div>
                        : <div />
                    }
                    {bidsToDisplay.length === 0 ?
                        <div className=" text-xs font-inconsolata tracking-tight px-2">There are no bids yet.</div>
                        : <div />}
                </div>

            </div>
        </div>
    );

}

const SummaryWithIcon = ({
    name,
    icon,
    ft,
}: {
    name: string,
    icon: React.ReactNode,
    ft?: boolean
}) => {
    return (
        <div className="flex items-center space-x-2">
            <div className={"flex text-red " + (ft === true ? "text-red" : "")}>
                {name}
            </div>
            <div className="flex justify-center">
                {icon}
            </div>
        </div>
    )
}

const BidSummary = ({
    bid
}: {
    bid: Bid
}) => {
    const { mintAction } = bid;
    const ft = bid.details.substring(bid.details.length - 1) === "1";
    //console.log("bid", bid.details.substring(bid.details.length - 1));
    return <div className="flex flex-row">
        <MintSummary mintAction={mintAction} ft={ft} />
    </div>
}

const MintSummary = (props: { mintAction: MintAction, ft?: boolean }) => {
    if (props.mintAction === undefined) {
        return <div />
    }
    if (props.mintAction instanceof Rectangle) {
        return (
            <SummaryWithIcon
                name="Rectangle"
                ft={props.ft}
                icon={
                    <div
                        style={{ background: props.mintAction.fill.hexCode }}
                        className={`w-[10px] h-[10px]`}
                    />}
            />
        )
    } else if (props.mintAction instanceof Circle) {
        return (
            <SummaryWithIcon
                name="Circle"
                ft={props.ft}
                icon={
                    <div
                        style={{ background: props.mintAction.fill.hexCode }}
                        className={`w-[10px] h-[10px] rounded-full`}
                    />
                }
            />
        )
    } else if (props.mintAction instanceof Background) {
        return <SummaryWithIcon
            name="Background"
            ft={props.ft}
            icon={
                <div
                    style={{ background: props.mintAction.fill.hexCode }}
                    className={`w-[10px] h-[10px] rounded-full`}
                />
            }
        />
    }
    return (
        <div className="flex">
            {props.mintAction.getSummary()}
        </div>
    );
}

const PrettyAddress = ({ address }: { address: string }) => {

    const { data: ensData } = useEnsName({
        address: address as `0x${string}`
      })

      

    return <div>
        <div>
            {ensData ? ensData : address.substring(0, 8) + "..."}
        </div>
    </div>
}

const RecentBid = (props: {
    bid: Bid,
    onClick: () => void,
    isSelected: boolean,
    isWinner: boolean

}) => {
    
    const defaultColor = props.bid.details.substring(props.bid.details.length - 1) === "1" ? "text-red-800" : "text-neutral-500";

    return (
        <button
            onClick={() => props.onClick()}
            className={`tracking-tight group w-full flex flex-row text-xs items-center font-inconsolata py-1 px-2 transition-all rounded
                    ${props.isSelected ? 'font-bold' : defaultColor}
                    ${props.isWinner ? "font-bold text-green-900 hover:text-green-900" : ""}`} >
            <div className={"mr-2 group-hover:opacity-100 transition-all duration-500" + (props.isSelected || props.isWinner ? " opacity-100" : " opacity-0")}>
                {props.isWinner ? winnerIcon : eyeIcon}
            </div>
            <div className="mr-5">
                <span className="font-catamaran text-[10px]">Ξ</span>{ethers.utils.formatEther(props.bid.value).substring(0, 6).padEnd(6, "0")}
            </div>
            <div className="flex-1">
                <BidSummary bid={props.bid} />
            </div>
                <PrettyAddress address={props.bid.sender} />
            
        </button >
    )

}

export default RecentBids;
export { MintSummary, PrettyAddress }