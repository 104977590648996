
import ReactDOM from 'react-dom/client';
import '@rainbow-me/rainbowkit/styles.css';
import 'highlight.js/styles/github-dark.css';

import {
  getDefaultWallets,
  RainbowKitProvider,
} from '@rainbow-me/rainbowkit';

import './index.css';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import {
  WagmiConfig,
  createClient,
} from 'wagmi'
import Footer from './components/Website/Footer';
import { getChainConfig } from './lib/ChainEnv';
import { Prototyper } from './routes/Prototyper';
import { ViewAll } from './routes/ViewAll';
import { Live } from './routes/Live';
import { Buffer } from 'buffer';

const { chains, provider, webSocketProvider } = getChainConfig();

const { connectors } = getDefaultWallets({
  appName: 'TheHours',
  chains
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
  webSocketProvider
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <ViewAll />,
  },
  {
    path: "/viewall",
    element: <ViewAll />
  },
  {
    path: "/viewall/:tokenId",
    element: <ViewAll />
  }

]);

window.Buffer = window.Buffer || Buffer;

root.render(

  <WagmiConfig client={wagmiClient}>
    <RainbowKitProvider chains={chains}>
      <div className="w-full bg-[#131313]">
        <RouterProvider router={router} />
        <Footer />
      </div>
    </RainbowKitProvider>
  </WagmiConfig>

);
