import { ConnectButton } from '@rainbow-me/rainbowkit';
import { BidderExpando } from './Bidder/Bidder';

const DownCaret = () => {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" height="20" width="20"><path d="M10 13.479 4.583 8.062l1.459-1.458L10 10.562l3.958-3.958 1.459 1.458Z" /></svg>
    </div>
  )
}

export const ConnectWalletButton = () => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus ||
            authenticationStatus === 'authenticated');
        return (
          <div
            {...(!ready && {
              'aria-hidden': true,
              'style': {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
            className="flex-1 flex"
          >
            {(() => {
              if (!connected) {
                return (
                  <button
                    className="
                    active:scale-[90%]
                    hover:bg-white

                    border-gray-800
                    rounded-md
                    bg-gray-200
                    font-inconsolata font-bold text-xs text-black
                    transition-all
                    flex-1
                    flex
                    justify-center
                    items-center
                    relative
                    border
                     px-3
                     py-1
                    "
                    onClick={openConnectModal}
                    type="button">
                    Connect Wallet
                  </button>
                );
              }
              if (chain.unsupported) {
                return (
                  <button
                    className="
                  active:scale-[90%]
                  hover:bg-white
                  rounded-md
                  bg-rose-400
                  font-inconsolata font-bold text-xs text-black
                  transition-all
                  flex-1
                  flex
                  justify-center
                  items-center
                  relative
                   px-3
                   py-1
                  "
                    onClick={openChainModal}
                    type="button">
                    Wrong network
                    <DownCaret />
                  </button>
                );
              }
              return (
                <div className="flex-1 flex font-mono
                space-x-2
                ">
                  <button
                    className="
                      active:scale-[90%]
                      border-gray-800
                      rounded-md
                      bg-gray-200
                      font-inconsolata font-bold text-xs text-black
                      transition-all
                      hover:bg-white
                      flex-1
                      flex
                      justify-center
                      items-center
                      relative
                      border
                       px-3
                       py-1
                       "
                    onClick={openAccountModal} type="button">
                    <div className="mr-2">
                      {account.displayName}

                    </div>
                    <DownCaret />
                  </button>

                </div>
              );
            })()}
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectWalletButton;
