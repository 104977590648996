import { useEffect, useState } from "react";
import { MintAction, PossibleActionTypes } from "../Models/MintAction";

const ViewAllIntro = (
    {
        mintActions
    }
    : {
        mintActions: MintAction[]
    }
) => {

    useEffect(() => {
        calculateStats();
    }, [])

    const calculateStats = () => {
        var stats:number[] = new Array(PossibleActionTypes.length).fill(0);
        for (var i = 0; i < mintActions.length; i++) {
            stats[mintActions[i].getActionType()] += 1;
        }
        setStats(stats);
    }

    const [stats, setStats] = useState<number[]>([]);

    return <div className="container mx-auto">
            <div className="w-full flex flex-col items-center ">
                <div className="sm:pt-36 pt-12 pb-12 flex flex-col items-center text-center text-neutral-700">
                    <div className="mb-12 w-[150px] h-[200px] border-2 border-neutral-600" />
                    
                    <div className="w-5/6 sm:w-[700px] font-catamaran  text-4xl pb-4">
                        Welcome to TheHours Gallery
                    </div>
                    <div className="w-5/6 sm:w-[700px] pb-12 font-catamaran text-lg">
                        TheHours is collaborative artwork that was created over time through recurring hourly auctions. Each hour, participants bid to add or transform elements of the artwork, creating a series of 260 unique pieces.
                        
                    </div>
                    <div className="flex flex-row justify-center items-center space-x-8">
                        <div className="text-3xl sm:w-[200px] font-light">
                            <span className="font-bold">{mintActions.length}</span><br/>Hours
                        </div>
                        <div className="text-left font-normal flex flex-row space-x-2">
                            <div className="font-bold flex flex-col items-center">
                                <div>{stats[0]}</div>
                                <div>{stats[1]}</div>
                                <div>{stats[2]}</div>
                                <div>{stats[3]}</div>
                                <div>{stats[4]}</div>
                                <div>{stats[5]}</div>
                                <div>{stats[6]}</div>
                                <div>{stats[7]}</div>
                                <div>{stats[8]}</div>
                                <div>{stats[9]}</div>
                                <div>{stats[10]}</div>
                            </div>
                            <div className="flex flex-col">
                                <div>rectangles</div>
                                <div>circles</div>
                                <div>rotations</div>
                                <div>quad effects</div>
                                <div>shear effects</div>
                                <div>background colors</div>
                                <div>rect windows</div>
                                <div>circle windows</div>
                                <div>tile effects</div>
                                <div>scale effects</div>
                                <div>italicize effects</div>
                            </div>
                            
                        </div>
                    
                    </div>
                </div>
            </div>
        </div>

}

export {ViewAllIntro}