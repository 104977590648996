import { BigNumber, ethers } from 'ethers';
import { useEffect, useState } from 'react';
import { Bid } from '../../lib/useAllBidsForJackson';
import { Background } from '../Models/Background';
import { MintAction } from '../Models/MintAction';
import { Circle, Rectangle } from '../Models/Shape';
import { useEnsAvatar, useEnsName } from 'wagmi'
import auctionBidsCache from "../../tokenCache/auctionBidsCache.json";
import {formatBid } from '../../lib/useAllBidsForJackson';

const GalleryBids = ({
    selectedBid,
    tokenId,
    onSelect,
    small,
    selectLast,
}: {
    selectedBid: Bid | null,
    tokenId: number,
    onSelect: (bid: Bid) => void,
    small?: boolean,
    selectLast?: boolean
}) => {
    const bidEvents = auctionBidsCache[tokenId].bids;
    const allBidsForJackson: Bid[] = bidEvents.map(evt => formatBid(evt) );
    const reversed = allBidsForJackson.slice().reverse();
    const winningBid = reversed.length > 0 ? reversed[0] : undefined;
    const otherBids = reversed.slice(small === undefined ? 1 : 0);

    const listOtherBids = () => {
        var bids = [];
        for (var i = 0; i < otherBids.length; i++) {
            const bid = otherBids[i];
            const selected = selectedBid != null && selectedBid.transactionHash === bid.transactionHash;
            bids.push(<OtherBid bid={bid} key={bid.transactionHash} onClick={() => onSelect(bid)} isSelected={selected} />)
        }
        return bids;
    }

    useEffect(() => {
        if (selectLast !== undefined && otherBids.length > 0) {
            onSelect(otherBids[otherBids.length - 1])
        }
       else if (winningBid !== undefined) {
            onSelect(winningBid);
        }
    }, [tokenId])

    return (
        <div className="flex flex-col w-full">
            {winningBid !== undefined && small === undefined ? <WinningBid 
                    bid={winningBid} 
                    key={winningBid.transactionHash} 
                    onClick={() => onSelect(winningBid)} 
                    isSelected={selectedBid != null && selectedBid.transactionHash === winningBid.transactionHash} />
                : <div className="font-catamaran font-bold text-md text-neutral-700"> {small === undefined ? "No bids placed." : ""} </div>
                }

            {otherBids.length > 0 && <div>
                {small === undefined &&  <div className="font-catamaran font-bold text-md text-neutral-700">
                    Other Bids
                </div>}
                <div>
                    {listOtherBids()}
                </div>
            </div>}
        </div>
    );

}

const SummaryWithIcon = ({
    name,
    icon,
    ft,
}: {
    name: string,
    icon: React.ReactNode,
    ft?: boolean
}) => {
    return (
        <div className="flex items-center space-x-2">
            <div className={"flex text-red " + (ft === true ? "text-red" : "")}>
                {name}
            </div>
            <div className="flex justify-center">
                {icon}
            </div>
        </div>
    )
}

const BidSummary = ({
    bid
}: {
    bid: Bid
}) => {
    const { mintAction } = bid;
    const ft = bid.details.substring(bid.details.length - 1) === "1";
    return <div className="flex flex-row">
        <MintSummary mintAction={mintAction} ft={ft} />
    </div>
}

const MintSummary = (props: { mintAction: MintAction, ft?: boolean }) => {
    if (props.mintAction === undefined) {
        return <div />
    }
    if (props.mintAction instanceof Rectangle) {
        return (
            <SummaryWithIcon
                name="Rectangle"
                ft={props.ft}
                icon={
                    <div
                        style={{ background: props.mintAction.fill.hexCode }}
                        className={`w-[10px] h-[10px]`}
                    />}
            />
        )
    } else if (props.mintAction instanceof Circle) {
        return (
            <SummaryWithIcon
                name="Circle"
                ft={props.ft}
                icon={
                    <div
                        style={{ background: props.mintAction.fill.hexCode }}
                        className={`w-[10px] h-[10px] rounded-full`}
                    />
                }
            />
        )
    } else if (props.mintAction instanceof Background) {
        return <SummaryWithIcon
            name="Background"
            ft={props.ft}
            icon={
                <div
                    style={{ background: props.mintAction.fill.hexCode }}
                    className={`w-[10px] h-[10px] rounded-full`}
                />
            }
        />
    }
    return (
        <div className="flex">
            {props.mintAction.getSummary()}
        </div>
    );
}

const PrettyAddress = ({ address }: { address: string }) => {

    const { data: ensData } = useEnsName({
        address: address as `0x${string}`
      })

    return <div>
        <div>
            {ensData ? ensData : address.substring(0, 8) + "..."}
        </div>
    </div>
}

const WinningBid = (
    {
        bid,
        onClick,
        isSelected
    } : {
        bid: Bid,
        onClick: () => void,
        isSelected: boolean,
}) => {
    const tokenId:number = parseInt(ethers.utils.formatUnits(bid.jacksonId, 0));
    const dateString = new Date(auctionBidsCache[tokenId].settledTimestamp * 1000).toLocaleString();

    return (

        <button
            onClick={() => onClick()}
            className={`w-full flex flex-col items-left text-sm tracking-tight font-inconsolata transition-all pb-4 
                        ${isSelected ? "text-black" : "text-neutral-500"}
                        hover:text-black`}>
                
                { dateString }
                
                <div className="flex flex-row space-x-2">
                    <BidSummary bid={bid} />
                    <div>by</div> <PrettyAddress address={bid.sender} />
                </div>
                
                <div className="mr-5">
                    <span className="font-catamaran text-[10px]">Ξ</span>{ethers.utils.formatEther(bid.value).substring(0, 6).padEnd(6, "0")}
                </div>
        </button>
    )
}

const OtherBid = (props: {
    bid: Bid,
    onClick: () => void,
    isSelected: boolean
}) => {
    
    const defaultColor = props.bid.details.substring(props.bid.details.length - 1) === "1" ? "text-red-800" : "text-neutral-500";

    return (
        <button
            onClick={() => props.onClick()}
            className={`tracking-tight group w-full flex flex-row text-xs items-center font-inconsolata pb-1 transition-all rounded
                    ${props.isSelected ? `font-bold text-black` : defaultColor}
                    hover:text-black hover:font-bold`} >
            
            <div className="mr-3">
                <span className="font-catamaran text-[10px]">Ξ</span>{ethers.utils.formatEther(props.bid.value).substring(0, 6).padEnd(6, "0")}
            </div>
            <div className="flex-1 ">
                <BidSummary bid={props.bid} />
            </div>
                <PrettyAddress address={props.bid.sender} />
            
        </button >
    )

}

export default GalleryBids;
export { MintSummary, PrettyAddress }