import { useState } from "react";
import { Bid } from "../../lib/useAllBidsForJackson";
import { FramedCanvas } from "../CanvasView";
import GalleryBids from "../ViewAll/GalleryBids";
import mintDetailsCache from "../../tokenCache/mintDetailsCache.json";
import { createMintActionFromByteString } from "../Models/MintActionFactory";
import { getSVGWithCheckpoint } from "../Models/Canvas";

const AuctionThumbnail = ({ hour }: { hour: number }) => {

    const [selectedBid, setSelectedBid] = useState<Bid | null>(null);
    const mintActions = mintDetailsCache.map(({bytes32, jacksonId}) => createMintActionFromByteString(bytes32.substring(2), jacksonId));
    
    const mintActionsToPreview = selectedBid ? [...mintActions.slice(0, hour), selectedBid?.mintAction] : mintActions;
    
    const SVG = getSVGWithCheckpoint(mintActionsToPreview, true, mintActionsToPreview.length - 1);

    return <div className="w-full sm:w-[600px] flex flex-col sm:flex-row justify-center">
        <div className="w-full sm:w-[70vh] relative flex flex-col items-center justify-center pr-8">
            <FramedCanvas SVG={SVG} />
        </div>
        <div className="w-full sm:w-[300px] pt-4">
            <div className="text-xl font-catamaran font-bold">
                Hour#{hour}
            </div>
            <GalleryBids selectedBid={selectedBid} tokenId={hour} onSelect={setSelectedBid} small={true} selectLast={true}/>
        </div>
    </div>

}

export {AuctionThumbnail};