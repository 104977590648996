import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Essay } from "../components/Essay/Essay";
import { createMintActionFromByteString } from "../components/Models/MintActionFactory";
import { ViewAllGallery } from "../components/ViewAll/ViewAllGallery";
import { ViewAllHero } from "../components/ViewAll/ViewAllHero"
import { ViewAllIntro } from "../components/ViewAll/ViewAllIntro";
import NavBar from "../components/Website/NavBar"

import mintDetailsCache from "../tokenCache/mintDetailsCache.json";

function ViewAll() {
    const tokenId = useParams().tokenId;
    const mintActions = mintDetailsCache.map(({bytes32, jacksonId}) => createMintActionFromByteString(bytes32.substring(2), jacksonId));
    const [viewIndex, setViewIndex] = useState(parseInt(tokenId ? tokenId : "259"));

    useEffect(() => {
        window.history.replaceState(null, "TheHours", "/viewall/" + viewIndex);
    }, [viewIndex])
    
    return <div className="w-full relative ">
        <NavBar />
        <div className="relative bg-gradient-to-r from-[#DDDDDD] to-white ">
            <div className="absolute pointer-events-none top-0 left-0 w-full h-full bg-gradient-to-r from-white/0 to-white/30 z-30"/>
            <ViewAllIntro mintActions={mintActions} />
            <ViewAllHero mintActions={mintActions} viewIndex={viewIndex} setViewIndex={setViewIndex}/>
            <ViewAllGallery mintActions={mintActions} viewIndex={viewIndex} setViewIndex={setViewIndex}/>
            <Essay />
            <div className="h-48"/>


        </div>
    </div>
}

export {ViewAll}