import { useContractEvent, useContractRead } from "wagmi";
import { TheHoursAuctionHouseABI } from "../abis/TheHoursAuctionHouse";
import { Auction } from "../types";
import { AUCTION_CONTRACT_ADDRESS } from "./ChainEnv";

const useContractUpdates = (callback: Function) => {
  useContractEvent({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    eventName: 'AuctionCreated',
    listener: () => {
      callback();
    }
  })

  useContractEvent({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    eventName: 'AuctionExtended',
    listener: () => {
      callback();
    }
  })

  useContractEvent({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    eventName: 'AuctionBid',
    listener: () => {
      callback();
    }
  })

  useContractEvent({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    eventName: 'AuctionSettled',
    listener: () => {
      callback();
    }
  })
}

const useCurrentAuction = (): Auction | undefined => {

  const { data, refetch } = useContractRead({
    address: AUCTION_CONTRACT_ADDRESS,
    abi: TheHoursAuctionHouseABI,
    functionName: 'auction',
  })

  useContractUpdates(refetch);

  if (data) {
    const {
      hourId,
      amount,
      startTime,
      endTime,
      bidder,
      settled,
      mintDetails,
    } = data;
    return {
      hourId,
      amount,
      startTime,
      endTime,
      bidder,
      settled,
      mintDetails,
    } as Auction;
  }

  return;
}

export { useCurrentAuction }