import { useEffect, useState } from 'react';
import { Availability } from '../../lib/useAvailableActions';
import { useCurrentAuction } from '../../lib/useCurrentAuction';
import { useCurrentAuctionHourId } from '../../lib/useCurrentAuctionJacksonId';
import { useFinishingTouchType } from '../../lib/useFinishingTouchType';
import { ActionType, MintAction } from '../Models/MintAction';
import { createMintAction } from '../Models/MintActionFactory';
import { JacksonColor, JacksonUtils } from '../utils';
import './Slider.css';

function createRandomParamObject(paramNames: string[]): { [key: string]: number | string } {
    let inputs: { [key: string]: number | string } = {};
    for (var i = 0; i < paramNames.length; i++) {
        const paramName = paramNames[i];
        inputs[paramName] = (paramName === "fill") ? new JacksonColor().hexCode : JacksonUtils.random255();
    }
    return inputs;
}

interface ActionOptionsProps {
    paramNames: string[],
    onChange: (newMint: MintAction) => void,
    randomSeed: number,
    actionType: ActionType,
    drag: { count: number, x: number, y: number },
    availability: Availability,
}

function ActionOptions(props: ActionOptionsProps) {
    const stateObject = createRandomParamObject(props.paramNames);
    const [params, setParams] = useState(stateObject);
    const jacksonId = useCurrentAuctionHourId();

    
    //helper function to update state object
    const setParam = (event: any) => {
        if (event.target.getAttribute("datatype") === "number") {
            setParams((params) => { return { ...params, [event.target.name]: Math.trunc(parseFloat(event.target.value)) } })
        }
        else {
            setParams((params) => { return { ...params, [event.target.name]: event.target.value } })
        }
    }

    //create the UI elements for each param
    var sliders = [];
    for (var i = 0; i < props.paramNames.length; i++) {
        const paramName = props.paramNames[i];
        if (paramName === "fill") {
            sliders.push(<ColorPicker key={paramName} name={paramName} color={params[paramName]} onChange={setParam} />)
        }
        else {
            sliders.push(<ByteSlider key={paramName} name={paramName} value={params[paramName]} onChange={setParam} />);
        }
    }

    const getXYParamNames = () => {
        if (typeof params['x'] === 'number' && typeof params['y'] === 'number') {
            return { xParam: 'x', yParam: 'y' };
        }
        else if (typeof params['cx'] === 'number' && typeof params['cy'] === 'number') {
            return { xParam: 'cx', yParam: 'cy' };
        }
        else {
            return { xParam: '', yParam: '' };
        }
    }

    useEffect(() => {
        props.onChange(createMintAction(props.actionType, params, jacksonId ? jacksonId.toNumber() : 999));
    }, [params])

    useEffect(() => {
        setParams(createRandomParamObject(props.paramNames));
    }, [props.randomSeed])


    useEffect(() => {
        let { xParam, yParam } = getXYParamNames();
        if (xParam === '' || yParam === '') return;

        let newX = props.drag.x;
        let newY = props.drag.y;

        if (xParam === 'x' && typeof params['width'] === 'number' && typeof params['height'] === 'number') {
            newX = newX - (params['width'] / 2);
            newY = newY - (params['height'] / 2);
        }

        newX = Math.max(0, Math.min(255, newX));
        newY = Math.max(0, Math.min(255, newY));

        setParams((params) => { return { ...params, [xParam]: newX, [yParam]: newY } })
    }, [JSON.stringify(props.drag)])

    return <div className="w-full flex flex-row flex-wrap gap-1.5 mt-3 mb-3">
        {props.availability.isAvailableToAllowlistOnly && props.availability.isAvailableToUser && 
            <div className="text-xs font-noto leading-4 italic p-2 bg-green-100/70 rounded-md">
                This action is available to you this auction because you are on the allowlist. Thank you for being a Jackson holder!
            </div>}
        {props.availability.isAvailableToAllowlistOnly && !props.availability.isAvailableToUser && 
            <div className="text-xs font-noto leading-4 italic p-2 bg-yellow-100/70 rounded-md">
                This action is only available to allowlist members for this auction. <br/><br/>It might be available to all users in a future auction.
            </div>}
        {props.availability.isFinishingTouch &&
            <div className="text-xs font-noto font-bold leading-4 italic p-2 bg-red-300/70 rounded-md">
                This action is a Finishing Touch and will end the collection.
            </div>}
        {props.availability.isAvailableToUser && sliders}
    </div>
}

function ByteSlider(props: any) {
    return <div className="w-full flex flex-row">
        <div className="font-inconsolata text-center tracking-tight	text-xs mr-2 ml-1 font-semibold">
            <span>{props.name}: </span>
        </div>
        <div className="flex flex-grow items-center mr-2">
            <input className="myslider appearance-none bg-gray-600 w-full h-px rounded-full opacity-70 ease-in-out duration-150 hover:opacity-100"
                type="range"
                name={props.name}
                min={0} max={255} value={props.value} step={1}
                onChange={props.onChange}
                datatype="number"></input>
        </div>
    </div>
}

function ColorPicker(props: any) {
    return <div className="w-full flex flex-row">
        <div className="font-inconsolata text-center text-xs mr-2 ml-1 font-semibold">
            <span>{props.name}: </span>
        </div>
        <input className="appearance-none -mt-1 bg-transparent h-6 cursor-pointer"
            type="color" name="fill" value={props.color}
            onChange={props.onChange}>
        </input>
    </div>
}

export default ActionOptions;