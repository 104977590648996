import { configureChains } from "wagmi"
import { mainnet, sepolia} from 'wagmi/chains'
import {localhost} from 'wagmi/chains'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { publicProvider } from 'wagmi/providers/public'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

type ChainParams = {
  chainId: number;
  alchemyApiKey?: string;
  auctionContractAddress?: string;
  jacksonContractAddress?: string;
}

const ChainEnv: Record<string, ChainParams> = {
  'SEPOLIA': {
    chainId: sepolia.id,
    alchemyApiKey: process.env.REACT_APP_SEPOLIA_ALCHEMY_API_KEY,
    auctionContractAddress: process.env.REACT_APP_SEPOLIA_AUCTION_ADDRESS,
    jacksonContractAddress: process.env.REACT_APP_SEPOLIA_THEHOURS_ADDRESS,
  },
  'MAINNET': {
     chainId: mainnet.id,
     alchemyApiKey: process.env.REACT_APP_MAINNET_ALCHEMY_API_KEY,
     auctionContractAddress: process.env.REACT_APP_MAINNET_AUCTION_ADDRESS,
     jacksonContractAddress: process.env.REACT_APP_MAINNET_THEHOURS_ADDRESS,
   },
  'LOCALHOST': {
    chainId: 1337,
    auctionContractAddress: process.env.REACT_APP_LOCALHOST_AUCTION_ADDRESS,
    jacksonContractAddress: process.env.REACT_APP_LOCALHOST_JACKSON_ADDRESS,
  }
}

const getChainConfig = (): ReturnType<typeof configureChains> => {
  const chainParams = getChainParams();
  if (chainParams.chainId === 1337) {
    return configureChains([localhost], [publicProvider()]);
  }

  if (!chainParams.alchemyApiKey) throw new Error('Alchemy API key not set for non-localhost chain');

  if (chainParams.chainId === sepolia.id) {
    return configureChains(
      [sepolia], 
      [jsonRpcProvider({
          rpc: (chain) => ({
            http: 'https://eth-sepolia.g.alchemy.com/v2/' + process.env.REACT_APP_SEPOLIA_ALCHEMY_API_KEY,
            webSocket: 'wss://eth-sepolia.g.alchemy.com/v2/' + process.env.REACT_APP_SEPOLIA_ALCHEMY_API_KEY
          })
        })]
    );
  } else if (chainParams.chainId === mainnet.id) {
    return configureChains([mainnet], [alchemyProvider({ apiKey: chainParams.alchemyApiKey })]);
  } else {
    throw new Error('CHAIN env var not supported');
  }
}

const getChainParams = (): ChainParams => {
  if (!process.env.REACT_APP_CHAIN) throw new Error('CHAIN env var not set');
  //console.log(process.env.REACT_APP_CHAIN, ChainEnv[process.env.REACT_APP_CHAIN])
  if (!ChainEnv[process.env.REACT_APP_CHAIN]) throw new Error('CHAIN env var not set to a valid value');
  return ChainEnv[process.env.REACT_APP_CHAIN];
}

const getChainId = (): number => {
  return getChainParams().chainId;
}

const getJacksonContractAddress = (): string => {
  const jacksonContractAddress = getChainParams().jacksonContractAddress;
  if (!jacksonContractAddress) throw new Error('Jackson contract address env var not set');
  return jacksonContractAddress;
}

const getAuctionContractAddress = (): string => {
  const auctionContractAddress = getChainParams().auctionContractAddress;
  if (!auctionContractAddress) throw new Error('Auction contract address env var not set');
  return auctionContractAddress;
}

const AUCTION_CONTRACT_ADDRESS = getAuctionContractAddress();
const JACKSON_CONTRACT_ADDRESS = getJacksonContractAddress();
const CHAIN_ID = getChainId();


export { getChainConfig, AUCTION_CONTRACT_ADDRESS, JACKSON_CONTRACT_ADDRESS, CHAIN_ID }
