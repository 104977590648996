import { JacksonUtils } from "../../utils";
import { Effect } from "../Effect";
import { JacksonByteParam } from "../JacksonParam";
import { ActionType, MintAction } from "../MintAction";
import ArtParams from '../../../ArtParams.json';

class ScaleEffect extends Effect {
    scaleX: JacksonByteParam = new JacksonByteParam(0);
    scaleY: JacksonByteParam = new JacksonByteParam(0);

    constructor(args: {id: number, scaleX: number, scaleY: number}) {
        super(args.id);
        if (args && args.scaleX !== undefined) this.scaleX.setDecimal(args.scaleX);
        if (args && args.scaleY !== undefined) this.scaleY.setDecimal(args.scaleY);
    }

    getSummary() {
        return `Scale ${this.scaleX.toValue(50, 200)}, ${this.scaleY.toValue(50,200)}`
    }

    getSVG() {
        var x = this.scaleX.toValue(50, 200);
        var y = this.scaleY.toValue(50, 200);
        var x1 = Math.trunc(x / 100);
        var y1 = Math.trunc(y / 100);
        var xdec = ("" + (x % 100)).padStart(2, "0");
        var ydec = ("" + (y % 100)).padStart(2, "0");
        var xvalue = x1 + "." + xdec;
        var yvalue = y1 + "." + ydec;
        
        return `<g transform='scale(${xvalue} ${yvalue})'><g>`;
    }

    getAnimatedSVG(): string {
        var x = this.scaleX.toValue(50, 200);
        var y = this.scaleY.toValue(50, 200);
        var x1 = Math.trunc(x / 100);
        var y1 = Math.trunc(y / 100);
        var xdec = ("" + (x % 100)).padStart(2, "0");
        var ydec = ("" + (y % 100)).padStart(2, "0");
        var xvalue = x1 + "." + xdec;
        var yvalue = y1 + "." + ydec;
        return `<g transform="scale(${xvalue} ${yvalue})" >
            <animateTransform attributeName="transform" type="scale" from="1 1" to="${xvalue} ${yvalue}" ${MintAction.ANIMATION_TIMING} "/><g>`;
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_SCALE.value);
        bytes += this.scaleX.getHex();
        bytes += this.scaleY.getHex();
        return bytes;
    }

    getActionType(): ActionType {
        return ActionType.Scale;
    }

    static fromByteString(byteString: string, effectId: number): ScaleEffect {
        return new ScaleEffect({
            id: effectId,
            scaleX: parseInt(byteString.substring(2, 4), 16),
            scaleY: parseInt(byteString.substring(4, 6), 16),
        });
    }
}

export {ScaleEffect}