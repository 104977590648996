import { BigNumber, ethers } from "ethers";
import { useState } from "react";
import { AvailabilityByAction } from "../../lib/useAvailableActions";
import mintDetails from "../../tokenCache/mintDetailsCache.json"
import ActionOptions from "../Bidder/ActionOptions";
import { ActionPicker } from "../Bidder/ActionPicker";
import CanvasView from "../CanvasView";
import { getSVG } from "../Models/Canvas";
import { ActionType, ActionTypesToParams, MintAction, PossibleActionTypes } from "../Models/MintAction";
import { createMintActionFromByteString } from "../Models/MintActionFactory";

const FIRST_10_MINTS = [...mintDetails].splice(0, 10).map(({bytes32, jacksonId}) => createMintActionFromByteString(bytes32.substring(2), jacksonId));

const ExampleAuction = () => {
    
    const [previewMint, setPreviewMint] = useState<MintAction | undefined>(undefined);

    const mintsToShow = previewMint ? [...FIRST_10_MINTS, previewMint] : FIRST_10_MINTS;

    const [drag, setDrag] = useState({ count: 0, x: 0, y: 0 });

    const onCanvasDrag = (pcts: { x: number, y: number }) => {
        var scaledX = pcts.x * 255;
        var scaledY = pcts.y * 255;
        setDrag({ count: drag.count + 1, x: scaledX, y: scaledY });
    }

    return <div className="flex flex-col sm:flex-row items-start justify-center space-x-2">
        <div className="w-5/6 sm:w-[300px]">
            <CanvasView 
                onDrag={onCanvasDrag} 
                SVG={getSVG(mintsToShow, false, mintsToShow.length - 1)} 
                draggable={true}/>
        </div>
        <div className="w-full sm:w-[200px]">
            <ExampleMintPanel 
                onMintChanged={setPreviewMint} 
                drag={drag} />
        </div>
    </div>



}

const ExampleMintPanel = (
    {
        onMintChanged,
        drag
    } : {
        onMintChanged: (newMint: MintAction) => void,
        drag: { count: number, x: number, y: number}
        
    }) => {
    const [action, setAction] = useState(ActionType.Rectangle);
    
    const availableToAll = {
        isAvailableToUser: true,
        isAvailableToAll: true,
        isAvailableToAllowlistOnly: false,
        isFinishingTouch: false
    }
    const availabilityByAction: AvailabilityByAction = [
        availableToAll,
        availableToAll,
        availableToAll,
        availableToAll,
        availableToAll,
    ];

    return (
        <div className="bg-neutral-300 p-2 rounded-lg mt-8 w-full sm:w-[250px]">
            <div className="font-catamaran text-lg font-bold mb-1">Create Bid</div>
            <ActionPicker 
                selectedAction={action} 
                possibleActions={PossibleActionTypes} 
                onChange={setAction} 
                availabilityByAction={availabilityByAction}/>

            <ActionOptions
                key={action}
                drag={drag}
                actionType={action}
                onChange={onMintChanged}
                randomSeed={0}
                paramNames={ActionTypesToParams[action]}
                availability={availabilityByAction[action]} />

            
        </div>
    )
}

export {ExampleAuction}