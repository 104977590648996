import { ActionType, MintAction } from "./MintAction";
import { JacksonUtils, JacksonColor } from "../utils";
import Canvas from "./Canvas";
import ArtParams from '../../ArtParams.json';
import { JacksonByteParam } from "./JacksonParam";

type ShapeArgs = {
    fill: string,
    opacity: number
}

type RectangleArgs = ShapeArgs & {
    x: number,
    y: number,
    width: number,
    height: number
}

type CircleArgs = ShapeArgs & {
    cx: number,
    cy: number,
    r: number
}

abstract class Shape extends MintAction {

    fill: JacksonColor = new JacksonColor();
    opacity: JacksonByteParam = new JacksonByteParam();

    constructor(args: ShapeArgs) {
        super();
        if (args) {
            this.fill = new JacksonColor({ hexCode: args.fill });
            this.opacity.setDecimal(args.opacity);
        }
    }
    getOpacityAnimationSVG(): string {
        return `<animate attributeName="opacity" ${MintAction.ANIMATION_TIMING} values="0;${this.getOpacityString()}" </animate>`;
    }

    getOpacityString(): string {
        let o = this.opacity.toValue(ArtParams.MIN_OPACITY.value, ArtParams.MAX_OPACITY.value);
        return "0." + (o < 10 ? "0" + o : o);
    }
}

class Rectangle extends Shape {
    x: JacksonByteParam = new JacksonByteParam();
    y: JacksonByteParam = new JacksonByteParam();
    width: JacksonByteParam = new JacksonByteParam();
    height: JacksonByteParam = new JacksonByteParam();

    constructor(args: RectangleArgs) {
        super(args);
        if (args) {
            this.x.setDecimal(args.x);
            this.y.setDecimal(args.y);
            this.width.setDecimal(args.width);
            this.height.setDecimal(args.height);
        }
    }

    getSummary(): string {
        return `${this.fill.hexCode} Rectangle`;
    }
    
    getActionType(): ActionType {
        return ActionType.Rectangle;
    }

    getSVG(): string {

        let args = {
            x: this.x.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            y: this.y.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            width: this.width.toValue(ArtParams.RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_WIDTH),
            height: this.height.toValue(ArtParams.RECTANGLE_MIN_WIDTH.value, Canvas.PAINTABLE_HEIGHT),
            fill: this.fill.toString(),
            opacity: this.getOpacityString()
        }
        return `<rect ${JacksonUtils.stringifyArgs(args)}/>`;
    }

    getAnimatedSVG(): string {
        return this.getSVG().substring(0, this.getSVG().length - 2) + ">" + this.getOpacityAnimationSVG() + "</rect>";
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_RECTANGLE.value);
        bytes += this.x.getHex();
        bytes += this.y.getHex();
        bytes += this.width.getHex();
        bytes += this.height.getHex();
        bytes += this.fill.hexCode.substring(1);
        bytes += this.opacity.getHex();
        return bytes;
    }

    static fromByteString(byteString: string): Rectangle {
        return new Rectangle({
            x: parseInt(byteString.substring(2, 4), 16),
            y: parseInt(byteString.substring(4, 6), 16),
            width: parseInt(byteString.substring(6, 8), 16),
            height: parseInt(byteString.substring(8, 10), 16),
            fill: "#" + byteString.substring(10, 16),
            opacity: parseInt(byteString.substring(16, 18), 16)
        });
    }
}

class Circle extends Shape {

    cx: JacksonByteParam = new JacksonByteParam();
    cy: JacksonByteParam = new JacksonByteParam();
    r: JacksonByteParam = new JacksonByteParam();

    constructor(args: CircleArgs) {
        super(args);
        if (args) {
            this.cx.setDecimal(args.cx);
            this.cy.setDecimal(args.cy);
            this.r.setDecimal(args.r);
        }
    }

    getSummary(): string {
        return `Paint ${this.fill.hexCode} Circle`;
    }

    getActionType(): ActionType {
        return ActionType.Circle;
    }

    getSVG(): string {

        let args = {
            cx: this.cx.toValue(Canvas.PAINTABLE_MIN_X, Canvas.PAINTABLE_MAX_X),
            cy: this.cy.toValue(Canvas.PAINTABLE_MIN_Y, Canvas.PAINTABLE_MAX_Y),
            r: this.r.toValue(ArtParams.CIRCLE_MIN_RADIUS.value, ArtParams.CIRCLE_MAX_RADIUS.value),
            fill: this.fill.toString(),
            opacity: this.getOpacityString()
        }
        return `<circle ${JacksonUtils.stringifyArgs(args)}/>`;
    }

    getAnimatedSVG(): string {
        return this.getSVG().substring(0, this.getSVG().length - 2) + ">" + this.getOpacityAnimationSVG() + "</circle>";
    }

    createByteString(): string {
        let bytes: string = "";
        bytes += JacksonUtils.decimalToHexByte(ArtParams.MINT_TYPE_CIRCLE.value);
        bytes += this.cx.getHex();
        bytes += this.cy.getHex();
        bytes += this.r.getHex();
        bytes += this.fill.hexCode.substring(1);
        bytes += this.opacity.getHex();
        return bytes;
    }

    static fromByteString(byteString: string): Circle {
        return new Circle({
            cx: parseInt(byteString.substring(2, 4), 16),
            cy: parseInt(byteString.substring(4, 6), 16),
            r: parseInt(byteString.substring(6, 8), 16),
            fill: "#" + byteString.substring(8, 14),
            opacity: parseInt(byteString.substring(14, 16), 16)
        });
    }
}

export { Shape, Rectangle, Circle };