import React, { useCallback, useRef, useEffect, useState} from 'react';

const historyIcon = <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M480 936q-138 0-240.5-91.5T122 616h82q14 104 92.5 172T480 856q117 0 198.5-81.5T760 576q0-117-81.5-198.5T480 296q-69 0-129 32t-101 88h110v80H120V256h80v94q51-64 124.5-99T480 216q75 0 140.5 28.5t114 77q48.5 48.5 77 114T840 576q0 75-28.5 140.5t-77 114q-48.5 48.5-114 77T480 936Zm112-192L440 592V376h80v184l128 128-56 56Z"/></svg>

interface CanvasViewProps {
    SVG: string,
    onDrag: (movement: { x: number, y: number }) => void,
    draggable?: boolean
}


function FramedCanvas(props: {SVG: string, draggable?: boolean}) {

    return <div className="w-full" >
        
        <div className="">
            <svg width="100%" id="framedsvg" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="-200 -230 360 460"
            className="max-h-[80vh]">
            <defs>
                <linearGradient id="sh" x1="0" y1="0" x2="100%" y2="0">
                    <stop offset="0" stopColor="rgba(0,0,0,0)"></stop>
                    <stop offset="1" stopColor="rgba(0,0,0,0.5)"></stop>
                </linearGradient>
                <linearGradient id="inset-shadow-bottom" x1="0" y1="0" x2="0" y2="100%">
                    <stop offset="0" stopColor="rgba(0,0,0,0)"></stop>
                    <stop offset="1" stopColor="stopColor:rgba(0,0,0,.75)"></stop>
                </linearGradient>
                <linearGradient id="inset-shadow-top" x1="0" y1="100%" x2="0" y2="0%">
                    <stop offset="0" stopColor="rgba(0,0,0,0)"></stop>
                    <stop offset="1" stopColor="stopColor:rgba(0,0,0,.75)"></stop>
                </linearGradient>
                <linearGradient id="inset-shadow-left" x1="100%" y1="0" x2="0%" y2="0">
                    <stop offset="0" stopColor="rgba(0,0,0,0)"></stop>
                    <stop offset="1" stopColor="stopColor:rgba(0,0,0,.75)"></stop>
                </linearGradient>
                <linearGradient id="inset-shadow-right" x1="0%" y1="0" x2="100%" y2="0">
                    <stop offset="0" stopColor="rgba(0,0,0,0)"></stop>
                    <stop offset="1" stopColor="stopColor:rgba(0,0,0,.75)"></stop>
                </linearGradient>
                <linearGradient id="border" x1="0%" y1="0" x2="100%" y2="0">
                    <stop offset="0" stopColor="#0E0E0E"></stop>
                    <stop offset="1" stopColor="#0F0F0F"></stop>
                </linearGradient>
            </defs>

            <rect fill="url(#sh)" x="-190" y="-200" width="40" height="400"></rect>
            <rect fill="url(#sh)" x="-180" y="-205" width="20" height="410"></rect>

            

            { props.draggable !== undefined && <rect id="dragarea" x="-150" y="-200" width="300" height="400" fill="transparent" /> }
                
            { props.SVG && <svg x="-230" y="-200" width="460" height="400" dangerouslySetInnerHTML={{ __html: props.SVG }} /> }
            
            <rect fill="url(#inset-shadow-bottom)" x="-150" y="196" width="300" height="8"></rect>
            <rect fill="url(#inset-shadow-top)" x="-150" y="-204" width="300" height="8"></rect>
            <rect fill="url(#inset-shadow-left)" x="-154" y="-200" width="8" height="400"></rect>
            <rect fill="url(#inset-shadow-right)" x="145" y="-200" width="8" height="400"></rect>
            <path id="framepath" d="M -160 -210 H 160 V 210 H -160 V -240 Z M -149 -199 V 199 H 149 V -199 H -149 Z" fill="url(#border)" />

        </svg>
        </div>
    </div>
}

function CanvasView(props: CanvasViewProps) {

    const [mouseDown, setMouseDown] = React.useState(false);

    const onMouseDown = (event: any) => {
        event.preventDefault();
        setMouseDown(true);
    }

    const convertCoords = (x: number, y: number) => {
        var svg = document.getElementById("dragarea");
        if (svg != null) {
            var rect = svg.getBoundingClientRect();
            return { x: (x - rect.left) / rect.width, y: (y - rect.top) / rect.height };
        }
        return { x: 0, y: 0 }
    }

    const dragged = useCallback((event: any) => {
        event.preventDefault();
        props.onDrag(convertCoords(event.clientX, event.clientY))
    }, []);

    useEffect(() => {
        if (mouseDown === true && props.draggable !== undefined) {
            window.addEventListener("mousemove", dragged);
            window.addEventListener("touchmove", dragged);
            window.addEventListener("mouseup", () => { setMouseDown(false) });
            window.addEventListener("touchend", () => { setMouseDown(false) });
        }
        else {
            window.removeEventListener("mousemove", dragged);
            window.removeEventListener("touchmove", dragged);
        }
    }, [mouseDown]);

    return <div id="svgcontainer" onMouseDown={onMouseDown} className="relative">
        <div className="flex flex-row justify-end items-start">
            <FramedCanvas SVG={props.SVG} draggable={props.draggable}/>
        </div>
    </div>
}

export default CanvasView;
export {FramedCanvas}