
const manual_add: string[] = [
    "0xf39Fd6e51aad88F6F4ce6aB8827279cffFb92266", // hardhat 0 for testing
    "0x65a9a59E5e039C26Ca594CB551663De3a32Ad68F", // .Jon
    "0xcDb467660Ba28D90b58f428C3c0eA75DC902a249", // Aurac
    "0x314C7C4086cbbF25055bE7Af2Fe8375A1A40Befe", // bbroad.eth
    "0x0CEdFb34DE64d7A57dd82eadc4eBaC6911327A13", // roar-y
    "0x4D9A6B620c7B2cdE0233448D144FeCb104F145f1", // roar-y
    "0xc022cd6d5751914eb756C139Cf9700Db8a4657fc", // lordof.eth
    "0x8c289148342568705095a9Bcf27eC157Fa767B3D", // lochnessi
    "0x2d551059E17Ccb8cA6dc14fbaaCCc72e97fc9c5F", // nocrimez
    "0x6b125Da6681BD46E7f0a74eefD8243cBb5ba7d09", // mariner
    "0xA9E7F4C62b61A54F6614FC4e2a6071bd3107398f", // qoobit
    "0xC4ec2f6Ee3b53Dfff7486cfd5b27b98a82c58EE8", // smarterdegen
]

const snapshot: string[] = [
"0x7cC64438C9356401594F840d24d7E9F9A2889c8F",
"0xcADe1E68A994C5b1459cCD19150128Ffef09Ea3c",
"0x82ca2f7e081D5dc9e2123c11B4ffc872C549CDca",
"0xA9d0537C300D8e3ebAC7a3E3C7c9A4fd8A731E9b",
"0x0846931641992A70d393771b5D9A1AB8ad5b2F28",
"0x3e234d09AD1708B02B34d19082Ab3C4737f638FB",
"0xc195183D257f60DfA505cC9188939Fed4774A2Ad",
"0xDB559B0C7E3c60e3AAfcC12bFd7977c0b7E28D54",
"0x59adDB7c436DEEfb04E8a6c505a35C6F0Cc2A886",
"0xAaDdd38d0E6EE4Fa5Df0856416803624BaF82532",
"0xAc104d56e4F8E0462f8C001eC7DDE4c68deb596F",
"0xb11171351e638cB4B7c49EC52f9FdA3EA1f363e4",
"0xa6e8A802567B90038c31f670a14CE71799f34335",
"0x192dfD9c08CD9e17cC695913BCA39b36EC425324",
"0xde70BDf7dfE13614d281716813274380a59E3e5d",
"0x4B2Db0D11560886d1231cb029cc1e8103AfD8F20",
"0x7a808dd45f17dF4208665c54F16c978D43adAE6d",
"0x96Dc89dbE84970ee42A2F0B35fB50599e6745FF8",
"0xaCd8C60B697e75323e84EE50193138f17Ab53c88",
"0x2846f10A4ADC6da64CD55a02FD6D3d87b92f5c05",
"0x3e6a203ab73C4B35Be1F65461D88Fb21DE26446e",
"0xcF1034c694Ef600335563c4866Ce97430713b77c",
"0x5B041643943B7112773fBb8696149c8D07e0F392",
"0x9DA41ffD0F4E7620EADcf73b3FBA86f18F33E5B2",
"0xe01A97FC4607A388a41626D5e039FDEcbfC6ACd9",
"0xDD2DE1DA47cf47449903F7E6B65d659CB1B8270a",
"0xB576652A3F98d2da5ee328D5792638E7C0C37f1E",
"0x2422011F4e0b66F5B78f5fCD6A80203ee57A3259",
"0xD9367acb5ddEF7229950b58Ad612e9511D539F46",
"0x9dC97966e63b0437601fDf8Fe52e798Dd536032F",
"0xD5cb77839DdeA1Fa370c2606f004ecA190629870",
"0xB24F71EDFaEb2f13d155E3116dbDec0E509C1110",
"0x0BF61023640B5fFa269EBaBa895cc3662793A4c9",
"0x08f9d1059538c8460dce4013234D5014CD93F938",
"0xd5E83F5fCA5854aCc2e49fD7B9B476c1D1fFa96B",
"0x30eEed8b7aA55a48728cfd7a1F7440c393fAF582",
"0x48268eC013b4191E4Bd72796bcD01b13babF7E2a",
"0xA18f977062cfC413371f301788c46202A500FA4c",
"0x5FA2B68FC1593Cd19AE0e9413830Cd20261002ae",
"0x8A6961E70f15c308b15Dd84C9CC319dA8FBb6Fdd",
"0x6dee5436c908439A7E961C715d83EFa640Fa545b",
"0xb8d6F735d89D49a5F7F629Bad790227454939471",
"0x7c46EC568DA8a0B5fE1BCAB32eBcaf3984D63f97",
"0xF158A700138e3d2Ee2d67f70f805725a4D5a5A75",
"0xc451Eb794fA883F066A62142d2D2374Ad6Cd8889",
"0x6e697Bf14d5c34d6c9017C200e4a9c4096692CC0",
"0xC8eEb7897C357DA808dA271f558B9334E030c295",
"0x570C680170F3f821A898172893428EB5AC00a62e",
"0xee97aF4BFcd42A0485cE70D424F38D1D5787C523",
"0x1e3c8D4f153036A70F2F55Fd72118A1C6B4Db0b7",
"0xE086d23192a0084f972DD8D0502769ed2074E69E",
"0x25EEbE6bd9aEe6FE65372BE51317A551Ff235293",
"0xe9f34031cE719e549d486dCE4cFF74748110e7a2",
"0x7b505D4ac6ab4CdF3FF86C48ebf4b3645377817f",
"0x32d5Aa798F16Be09d7c72F9a19bA6fB66e8F92F2",
"0x2Ee8670d2b936985D5fb1EE968810c155D3bB9cA",
"0x347332F8Dc7a79aDE3F2Dc827517a9859Ed201A1",
"0xBa3C3b068d952110E09a8968Efb1D31e9AFE5058",
"0x45Fc0da8916455497f66a0dA873314f3076E33D7",
"0x0b7a0527e4213dc4857Ab51e6c1C461Dc687aDde",
"0x42225B0C8C3f0c7C4d5B21a008a899e9a4992788",
"0xED07B5Ff7755687ac9DF365c6fA3399a6CD71065",
"0x667fE49FC9109c59d0221359FEF0567F1A79ef40",
"0xA5Bbf5B52Ee1857Da92feb043E5dD14C8124d550",
"0x07D3088a697DC1647413E0B7393746Dd2D6c8A55",
"0x13dCdADeA84242eacbb956F16FD5eeEC593F1731",
"0x13A164Ab4Ef73704423AD2D9A57C7b72E3c55E7D",
"0x7ff11507c9f7A350dED807166d4ecB59F22f6594",
"0x8969E3e06287c3B06759b7Db7b17Dd27C1264494",
"0xe26B3D20a9c28DC57931f98CB5E61AB5a379a063",
"0x8eda5967E823C48665C797d1E35b59B5e05162Ed",
"0x5c7aa6c9Ca8219a9887a9e16aB9A5bC283a928fF",
"0x1593c9aB842c36a926aE39CB2173731f9362E8Fd",
"0xa0e8B6b613112A3b4BA6a2713c024eF4bBa23A5f",
"0x32f4AF8B1A19384245e4a88Ab5CacF0006C601ba",
"0xe493d64DC68EDae2A14fa67c6fC34E2A1566313B",
"0x4bBe44332cb681124eBD99e1f50cE28d0c11F1fB",
"0x5CF27815411D0eAC8EB726E7227fE09828a85780",
"0xBf0F10B3FEFbfBB8F74387879C8812763444a5D2",
"0x1b716b052445D869c5b49d086d062A815dd6cD58",
"0x07D0d6Ba5a80432dB2c82f782f784d1FA2704D3a",
"0x17d8E3268B7C81111C98b7D3cCeA55072A32D6C0",
"0x5e58538cc693b1099C68d7fEF963C1f148DaE8CB",
"0x1F575360c38A0947b92407642219aB9D5ab44d9f",
"0x221320D34800760E06B206aCd01e626e463eB03E",
"0x11D754b2Ead9e83543da0BF29D008896770352Ba",
"0x90a23f18C5CDC1755821E65ac88096fad5936b04",
"0xFd79248460974b26D6931A511688535D85046E4a",
"0x4fd1370aE3035e6Dd41E81cb00c35ca40d16AC91",
"0x75863C5dd30fC162D7E6b30a6cAf015cc4c066C6",
"0xE199c1e7EC70563B391E2877e31FA9fF6316F150",
"0xD3B605dd135358cA50cDf59aF570Ea3ab717BD82",
"0xE6a76224F94F3D8bA641D3717b0e0999F53D0332",
"0xa56819Ce56d6d5B19712CF8178b3877fBa4Ae807",
"0x8C2D9C1a98944963b4693f5d30F57056aA2485Ce",
"0x7Ba3D1C4F46516fb975FB012F7db04381188e907",
"0xc9113b628371f57C9a46B6a8Ef3BF39cEa4977f9",
"0x8b4Dde8A951B0e77ee2f01575B61FB0AAD7B6184",
"0x69a905Fa175201A79f12046292e4E7121F81C7b9",
"0xab1B0602c6b68d5Ad775A78c437aD961bEC0ee4C",
"0xC02222A078d1706994e47292266239aABeA7FA8B",
"0x2775A91e5Dc9EFDb07b5d98886e140561c28D9C6",
"0x731Fa82b217d0a9DE1175EF794D1C96ecCEb283F",
"0xB6d820e80A2A7c49F94c9D6C8ea08DCcEDC9CE4C",
"0xb655f1aDb966F38c5159F370121E9633c2955dE4",
"0xC612D3C8B14D9C60b62fCAA91e3f58F2F43c6CfC",
"0x55Cf34223929A9D893C1EA402735a3a6FC6e5F74",
"0x94e310f2201DaaCd50027f5e49a4640cd7532fBd",
"0x0AD2022cf9ebA32ACCd0dF514B711C220eCD43E2",
"0x28171894acf66eB26b33807582c021321a64285a",
"0xD53154375a02b303e44d4D04e5a4dE1ba6eB873c",
"0x0c13e0C3750917923C678DD069F559DAb42e6eAA",
"0x7Db832D81325AE555f68A9667D0aB71f08d7E47e",
"0x50A0773742Eb17145Ccf2e8beb48B1b80df0143b",
"0xE1C3774Ff17F486779F4Def263d6f901FF874D1D",
"0x9d2fD53d8a48339BA9a151D5D92359FA69543343",
"0x682be7e1dd6f6762748cAe04eA7b8E174C5cAB53",
"0xCb69c5838AFD73317ABE43ea23574DDf7A6E51B7",
"0xBf44f843e8a26888f65D262D02a12f5884A7750E",
"0xfaA614c9a446D267A2885D3Ec49cD1B5b7add82C",
"0xd9334F4D63db199E3867fF45A7b41e9120aDa090",
"0xbA8E220834c32Fac376BBfb33820C001f022d72E",
"0x9b97b2478a461376D1861c13161E2d34ff643bDE",
"0x85fB73ac9d060d76251eF91310807434a1D2F569",
"0xb2af1b81283C87A9F06bA2294dca29f83A7D1d00",
"0xA80209B06d7F4c0028b3A3AAE4011357b7eaF752",
"0x458475D0fe1844C747730C85F26BF0f08fF75742",
"0x1CE9d62Ac3DD1b897d582cDa4075793ee8D0bfD3",
"0x7758E63Dcfe8D59E6f632a01Af8B1De96eF532E5",
"0xE2af0fDDd929Aa226C8276945f3804B92aCB89a7",
"0xcf34085D4Ef97D0384E53937c2856482d4157A9b",
"0x22e2ef078ef1CcF0a75D113a8B8389CccF746ff9",
"0x8E166ebb2DC4686640BC70E47FDea1d2615CC443",
"0x3cf39Bc1B7453d849Df3B4f34403D4eFE6051B67",
"0xb2C46b3123f4D40f268DCa4572E507bf0e002978",
"0xF905E552d0851241B0693E32C7a1b958c5289D4e",
"0xcA7C6D44A90899D33d0b56046aF84BC420eC8153",
"0xA769f8a07707C8bda67AcD55c579f8b119A80AF2",
"0x7252B80DBD14EbD63BCE68dcaBE529B0Dfb81025",
"0x42Db77c4eE2260DDF31ecc9670Ed38bc8D61A811",
"0xa2136110A9a7AD8513f2355921B8e29281A3B46C",
"0x40415c1c0E7a8ff3E184b51A94a2Fc5B92Df9434",
"0x41aD67f7d547c3C03b45a1fE1239D6ce390638CA",
"0xe8fE9295Cdd4fd2a6EdD0CD47EaFb6d61D8e50E1",
"0xAB0e2f3C3C29DeAa7C3ca0DaceC97a33d5C26708",
"0xfC7c3e555c6846b9F40011Aa0628F79687770669",
"0xf983247bd4d513609886edE62fF067dD6fB9681E",
"0x103734719dF360360047d9eb0bDA95f54ddEd557",
"0x8F3A94725a7686055D1aaBa8a41be506c5dfD079",
"0x9BDe2f2B4F892Fbf01598878776F56dE604F5E47",
"0x886478D3cf9581B624CB35b5446693Fc8A58B787",
"0xA043142e0a13ccDE6c14D1c1fB68DFd14De17feA",
"0x5A292988DaEFc719e8102098a0d578A5078deDC5",
"0xe424691B0848Fc8848D7c80B2E175EBAcb639397",
"0x7A0B57c39dB6b19f68D879ca392e667d61af8A1E",
"0x4461E1ab21E92161994Cc37b984276c2aE1e8B2e",
"0x3f5aeFe4f4A6EdED6E2C5FC01d391717DBdD2c26",
"0xB6024dCcbe36F0f595378b4cBCe44F7f3Bf4d583",
"0x42Dd10D6315EcDfCBfcc8EcEaCCa0BdC9539acf2",
"0x9eeDe3eb0d9886E34ea158F74Db8C5301D1155A6",
"0xea5c3836eB06d7bBEa785980d787D8bCe19DdA7a",
"0xB7D7923A1eaCAC1F6e20bC673AEDD530123BfAD8",
"0xbC6e70CB9b89851E6Cff7cE198a774549f4c0F0C",
"0x00AD79f63660AEA799Cd31A9DE7F18b302008709",
"0x340C326884f85Bf1C64F20baC304D865dC6fC597",
"0x11a67796a849d19AD42Be5C904F1f5bB068D066C",
"0x61C5fa0C1beD50Eab527e69608D1da11bd4D1BF3",
"0xf04BA91d645068bD41951144D59acC4b118ad629",
"0x11f5365D618D3fEC45928af60550f90B1F5C3593",
"0x0cDcF2D3746971ac3D96448d77469bA02ca79CE1",
"0x8B1b2986A16330CEC57C5edD80b00321BF2679a1",
"0xD34Eb3513b32a4803322813fE05D151615E55D96",
"0xce8Ad80ce1a979381d209Ac230D02aDaFb9Fa897",
"0x6e5BB242F9Dc1Fd782a31AeE659D5691fD34C938",
"0x06a992201c2b6F496D6c901406a7fA88566547A8",
"0xC685c7b302008C7AeE77006c945238fcC6E3513f",
"0x6fDdc55577c2FB9b6775dD53E0721c121920AC20",
"0x24A43F152Eb878CFEBaE52f7dC5508df33fA4359",
"0xCAc7AA6a758a47e9DDFC4a2deC3FEb1378EDF92E",
"0x2307b8334c098d5d255b6633F771716Aa9cdF4F4",
"0xC31397993416cF70e8aE1845AD577eA950dE9375",
"0xdf44Cb56916B7692Fb13a33CF9707CA840A11142",
"0xD698f3343DA43e962053Afc6d5b052e15a57e982",
"0xa882e53F63859807eF67f3bE69442168a38bF1D4",
"0xB7a38727b35313d45b87917a34F397F9ba9170f2",
"0x2bAD77c1aE11FF611bD23f6f350D23079AD8A6E1",
"0x1AA963E68CFDE4dab1Df1E15E70B2BB4103c4848",
"0x19c59cf6e49912e644f2Da222aE2645BC78264c7",
"0x487244a34cC5cfEaF8553D43eD6A7aeE92fCbc4a",
"0xd1938759bD2917cd8A7DE3D43e7C595cc58c5c85",
"0x2e48d199908a265FfeECa94825d3552FEBEbD9da",
"0x4c8736F7DC3E64295eeb1f53aA900824E7165D5A",
"0xaDAcbA4Cae9471C26D613F7A94014549a647783C",
"0x872fC45Bc46E666d4d0aFB1a1758785b985E4D61",
"0xC5e0e35921eE4F31995347ae2B6dF2A825edCda3",
"0xc81d2037CE95df559b8d5889A56F540C21530A3A",
"0x64cde4c05547FAE946D0e77fbF098b7dc71693D4",
"0xDe2D411dd7c5bA3Cd682e128A3E59A351Ec814C5",
"0x496d8EA7a37ad6B5BA066eB5DA715585dd0fA24C",
"0x83Cd2B925293E1F096C58b68Fb3259A64e156d8a",
"0x24deB9F2D63B1afbB9D31c3566b5fadA79D215a3",
"0x7EaBFE610657218bB4FD5620ED699B14dE305412",
"0xFCB67Ed7aAE97d8234ABFc24B66644e5563d6C9F",
"0x93f0cc9e8474B05F0F52Bc9890a022866BF18476",
"0x87f6A12D8Ce049419442Dc9517199A222A531040",
"0xDeb18626f55192398945E89fd4F24EEAEb9282bf",
"0xeeFbdAe2b62F9A1639bCBa81A7E76e84B2C6813B",
"0x6f0450627544E7A2F3E5c619a38a75d2034E28A2",
"0x7820a7BB5E3c1a501A889a6c22F6bEC5D57B336a",
"0x08609aE25d14E53A4B67b067Bcd856DF61e88c5D",
"0x04Fbd11f47b40Fe85bf4371d56661762Be14E997",
"0xA76e4a70531edd1BBF03eF5B3ef2db15577FD9C3",
"0x5d5B22F2881648E4d2f827C3f4f23B4E0FA8fF09",
"0x27FC7Ad00042e0550E0D0C94f4A00E53D20898E3",
"0xb3924EcF19c2ef7fB7cE3ecDDD22ec96C77a3f64",
"0xF074e7b578a2F87373b297726D35798d9d6Af7E0",
"0x99749c16823E57Cb766AB30D931398fcA6c76F54",
"0x5823d64D058967A2EB1495d72B82872206BEef8C",
"0x658b709ef127AFAD3B3164f6fbDABFA8791EB09e",
"0x04D6F51f6B4d707d9e53C1E82267Facd5d036Ab3",
"0x3D2d0Bdef98dbD86946B875ce8c4aeb07004baeD",
"0xF1e96206A1ce0877F06325a845A778b8eADB3f86",
"0x1cfeB09ed5953E22aE414AD2175A0f8492486ffB",
"0x90394BEFDf60140D099B828252C04eA9C13a3BE5",
"0xbA88168Abd7E9d53A03bE6Ec63f6ed30d466C451",
"0x321fDfCF79EEAED39Fc67dAaCa07d92457129d4f",
"0xc58537A9c71e7202C79c9AbfEeaf4EE0C48F1Be8",
"0xEF76cf033F7feE8a75c7B0458f138122B38C0b68",
"0xbE7f48d8eA71101890F7440e9F01Cf66562cb7e2",
"0x8B91C0Dd24C4Fd4F973e49ED8568921e31CF38b7",
"0x15356912016E5cf2eF7d017B7d218d17B41dB498",
"0xbab70C96C0f7836bdAb5a888E470BBaac06c089a",
"0xAF4D3eCEbe3c195C8C46DF4a95a22e6B84D53468",
"0x137878D2e1cA1739e3F584bDf43741a739Df3E7f",
"0x19bfA29Ce0d61290BA3f64Be2700d41850873fAD",
"0x6442aE8d06Df40a5E634de2E33A76297Af8B33d4",
"0x7319e28bCebaca92eC406Ae9d754D095Ca87D4B6",
"0xE55c9840eb6Ba1c75160Ed611E3C72Bc438dCA54",
"0xb86fDE964223c72c35C6da7d98c5D08515FdF0CE",
"0x5dA302617822144A51f2107Be45182Dc6F0f5821",
"0xF27F34c97b3ea78988C7EAF9b658AdA3b152b38d",
"0xaFbc3F98EEDB5f9A25a4AB2232d1346612efE77C",
"0xA4a5EF3661A7e8F58536938f268d5712ebe0Eb91",
"0x557c60995797fa7b47Be105227A2e46148D85750",
"0xe2b4043E80b2ae686D922C2A3C41f9aaD20025dC",
"0x2431742D0094F5523536F65F50dA103d690B63f2",
"0x44516032E9D220073dB4095dD851FAD06611C5eF",
"0x8F5656023D8E9b5DAdbC4316e6F2d0C450a7053F",
"0x1057B6adB95680C811c256A393F5C523d94fd6a6",
"0x3069e7A2f85e7d7874Fa448B1148ee4Abce80A19",
"0x13C75cfBc769B53139593f8b0966539a92204D58",
"0xfa861eE098C87c78AB96d780C5211d9557869920",
"0x3b13c2d95De500b90Cbef9c285a517B1E8bBdcEC",
"0x9B0F8ADC7CE5c3A8F23A2d8E6ed2F9Ec6A7246B8",
"0x2aFBB1a674dd1cE65F7B3D88Fae4Fb4a9C487526",
"0x369712Ace28dd9d7AC6276cd8Bf208a7C51E2937",
"0xE6e7bb336daCD37640311FbB099f7580172F5613",
"0x21095E00139932EB711b05b8b65c13f7A8bc2a4f",
"0xdDc2F1867173481CF86432C4f31539D19F95B556",
"0xF9dCeB45b278484AFE2544272DB78f560b910aCC",
"0xAC145F637FF9C6996e3199B735D857Bb14ccB715",
"0x419D8DCd1BEDCD2B843A6987515B986A67a58088",
"0x9577C95D024b37Fc8f9837aFac29748bCFB8d983",
"0x3d9D270A41dd9c38e370127998B98A6d4262b240",
"0xd8266D92e82b80DEC884cCcA9904Bd3CE35042C3",
"0x741aaEA52E2D9C815c6c0B9512e956D7094a2933",
"0x0c5780A740F75D94C530a5a665076eB65E3DAC80",
"0x64f29D9cd5c6C26Ca3d9Ed47fC690126EC79A2A6",
"0x48C531e24d956500385bbe414b14b76162dAd1d3",
"0x8a17cA2bda4E88Ba22787EDd85de72EBa34F8661",
"0x813c4C990AE6F6Ea2CD6F172F4D1D2096D79a8aF",
"0x7996814A66F36679bb64fa2bAa294E9bD5E14Fd9",
"0x17fdD5aB047e502ce1faa065f49170875b083a47",
"0xAf06911f6AB85b3a390eBB12e5034cCD73E0dC78",
"0x95F06f6782Ea8564248397A3C78Cd4d54df01897",
"0x837d21CFDa71E93e5257f95ce2C49751675EBcb1",
"0x6a4Bf9949dc169E79762CC116E309D08a6Ea4A5E",
"0x76e904F6236e7a2C7a91bB64Fb9Fe8546C72136C",
"0xD6Ce702632F94069E138D30836194B121f047E79",
"0xdf4008ee74077317CFC02dC6F31D832014978AA8",
"0xC7ab1D2F9508A4B6A7feea042C3c7424a06FF172",
"0x2e0AAa9BAb78Fdf4A050b2c516A50524Cd45ed07",
"0x4b151b7bFCB9CF5Fd6f0e49071855f1656898e44",
"0x716103393284064c8DB76D28961c8CfDD89405Cb",
"0x07f6C7902bB62e4Fa202356eFf458dE266C122C9",
"0xd778fe48742B544cD091241ea61Ca65a1a076a6a",
"0x1bEdf2f55F3f81D0b5018147A882E6d00d2aaCA3",
"0xd2dea18D040152c580F29195B29670633b0C9796",
"0x01d4636330E24225f78a7571A5682dEbFe5c7f2A",
"0x3E8fE33b9f652576aE48A470361465AaF79cf1A2",
"0x9047559D93e21328B4aE2F8DbB7b2B5345E0f200",
"0xBdFC087A5C32F6B6E425697c1A19a10E378136eE",
"0x95DB30325fF0bB290C8c224537f2AA2d67ABD3A4",
"0x816837C9D515b208c713cAE8AC072358C2BB2521",
"0xdf945e451c57948C72f29F35eD5c6acDd003Fa81",
"0xBcB45C0788fb9Aa662B6E19C110f6B090abd1884",
"0x20048Daef72ac92B52557CFba5044516B1725d90",
"0xAb3537b7C4bA6EF2D30389b59E258d524b46E4c8",
"0xB2cFdC1d0152E8C660aa57cBd323A8ac624f32C5",
"0x5b44393855C6590Ef5dbBa26a40B934542fe5Eb0",
"0x2d551059E17Ccb8cA6dc14fbaaCCc72e97fc9c5F",
"0x4D651265704fE4F38C903F3a2439B867901c8A88",
"0x21A19c4B5827e88ab01fa7a75E85C89c4F597D22",
"0xcF092eD40471F285a19622375B9153e7eF41B167",
"0xD147021D4e009FBD0462457f1c799A9124f6FDaD",
"0x1E6Bf209B99b2a692750C5790f41C31e22ece1f0",
"0xD016063Aac2d468303043AA932Aa7A0c92d5F065",
"0xf89C94f43B36719046b55E2AE60BacBfc0dB1C6a",
"0x03475A1A1FFfd7104b3D4DAef49c892107420C33",
"0x18057463877204E0BEc8082AbdC192Bd717c311F",
"0xC871E14826586D22E4AbDDBF6741C941842FC8bf",
"0x5ADBb3C856f676BdB50875366114F7644b82F781",
"0x5168895228E49c785d3D77A4621F076403Da79A0",
"0xB48393dfC231C96AbD4d3e46774DCcF79f51f240",
"0xfe9c0D10957eF06Bda064F9Ad5e1b685E3A1ea1B",
"0x091991a658491b64B4c4C3A5ca32F311439d504C",
"0x4cabe29909E1B94f52560799629481D570F311cF",
"0x228f9fD74F35080771A9e858f9575451d3ccF7ED",
"0xf38f39bb4B287E7129A1D7A61d31Fee39fAe7F42",
"0x498dE87f620f9dB59604dCC2EDC5aB7E7639baa2",
"0x95CDfc7f37Ce3ec32e30c1B2A8cFb605ACAc00E6",
"0x212581F9295A987BA85Ab2d0e2E68282EB63b67e",
"0xAAbd9a88bB2d21b72420572007ea8784C5124809",
"0xf7A4AE94Cc97c58a22A70b655778625D9C754A83",
"0x8607B8D221ACF921b3895796877f3a9dCb123a77",
"0x75f6F54eacc28354f38100f92C71B7418894DF73",
"0x17D6E3B842f882Db28C90566f26245AE51Ee5727",
"0x40C3a9FbC5cbcc3910D191Dac938c8EdEc0e768f",
"0x96FD61202A698ee3EAC21e247A6b209ea5ffEb91",
"0x3bc1b52d90FCd503031Ca39F718474f3B42b2200",
"0xa6134EB7381978804646088Df4BB42c5232D82Da",
"0x5f56E6dBc21c19bE60D8E33360F2B1674F90b74f",
"0x6b3210caa59c0367EdC0b0e60636dB02A623cE58",
"0x09564716E12eB2ca51f64d07d76AA88b7CDC0283",
"0x2A55552510409167fe935dba29AAe3f18e7a1A5D",
"0x7F0073E6Fac4E88A3827B9B8ddc1aeC84602E433",
"0xFAe9BC4D8cd2B8c9909a35Bc98fBB87AEd6EEFCF",
"0x58122b2bFe5a0eB10bcBb07f546D482c8E804E03",
"0x3b16aD41e9C70fAf0FA7A1b76744C19511458249",
"0xd698496b909bcca575954E13Ccee782c01548f56",
"0xe290f97Ca1f14cb2C8083bc7f1846158d93BB456",
"0x18C910B2A91d75947B2a4F3E452Ae16a1A948400",
"0x879765FfD7F2FE105ed3AA42B15b5F5aD241F0bD",
"0x7D3c21D9498249d7066b0c4eEE7409534d051bC9",
"0xc022cd6d5751914eb756C139Cf9700Db8a4657fc",
"0x93A08b47b7a35eceba8e0961894152236f275f04",
"0x7Bb3fAD216678a47cAeed210123fc9e9E263030e",
"0xC3488F6246863aDc0EB568Bc05B0451B1db6BdC0",
"0x5e95b00EB048bA226e37f5FcBaB2eBd8B530C83b",
"0x90c0e8C6E4791B9e4F99272fD70016a682056847",
"0x95A3E0c0741E3Bcd8FD1904dE1A2eca59d660dFc",
"0x1218eaE23A52af1e29E74695c64f174204A3b705",
"0x61436ab2FC743BdCecCD23Ef03b3c7e7c945C86B",
"0xA888626079eE1B973b862f690F1153F325C29F62",
"0x430380E004F369fcB58701d325B339195cECB9DC",
"0x362c6b703C15baD64b08402B4D7e3d3FF844849d",
"0xa1AD497E1780975daB7068977989160d071De523",
"0x03F58F0cc44Be4AbC68b2DF93C58514bb1196Dc3",
"0x419CD705b540eC01dAF54f10C98c3a643583D700",
"0x6D8dEC3fD68D94a7189A98346EA52B4D32e00012",
"0x47938933871c964c8b3D0Ba080816a886f1223f0",
"0xdCE0F3Dc0730C8B09aa90D2FE9079AED2AB97070",
"0xAbFB849eeDFe3A34002372e3E1a8ED945D28a02b",
"0xdc29bc8e55D7fAcA68423A386f65c0E3B2191E05",
"0xbA8227524A2843395345B0126CD763Fef65d6333",
"0xE24cf5250B1df55aC96B645A7E684bC57036ABbC",
"0x75B772F2Bb4F47FBb31B14d6e034B81CB0a03730",
"0xA1f8cDbf38E2acAfBc5F0FbFe1Ca9446d5988841",
"0x0918cA7e812AFDfD63B2136c9b3CB8bD9A1247f4",
"0x00cDa37BfC3Dd20349Aa901Fe8646688218d8772",
"0xAa6238D9d4444b4bC4F4cD569A3dfBfd06BD8188",
"0x0BBd4fcc810F3040aEb8cb37CBC9Ed6D0e68E683",
"0x6f96A08D5CCFE4c9712670dC17a0118441CC621d",
"0x040965AB8380242d92533C285D415ec99bb36F59",
"0xf4be2e4bDcA600F09E03b2154b301cD21E2eCC0C",
"0xC665A60F22dDa926B920DEB8FFAC0EF9D8a17460",
"0x165177423D4dacB3E766ad69BDD42dc1210099F0",
"0x3F0BFD370E2C167B7E84421dc678F3a6e6b86a3d",
"0x12B115751E0f608dA1281d594351A8A28816cE79",
"0xa5d981BC0Bc57500ffEDb2674c597F14a3Cb68c1",
"0x82aF7581909a9a9dd85c41180dE73925c39fBF38",
"0xa21FeD132341f41c7f96Cd9A5CDb3965bc0b74B0",
"0x0EE900E3d007E3da7aaFae9b541d1C0C738842df",
"0xB7B7C89dF75bEC457CfDECB572D9d0d90d3fd3F2",
"0x6Fc8Eff5418a86E0642139c32FD0E0dd14893874",
"0xD288e07C83bdf0c234d495DB5F0CFD16E685EA56",
"0x74Bb4995D5F1302b55b14BF6c1Df9eB39e3F57Ce",
"0xf63d7DBe24Ca439eBC699311001a661555a93040",
"0xBeeb5f36AdCfa5a4f3eB105b6b080A2AAC46079A",
"0x0F3C76Ade30aDf61b64D01154911308491784dfe",
"0x119d4549F03FfF680d4f653b3DCE056a3637d789",
"0xEFe90ff7edE905a116D46B2F3179Ae265ebFF3A7",
"0x01C383dAeC19b13C6eb3c06551a3A7eF81325A73",
"0xdc30E416EeB593ac735C344181E8E188b2400a70",
"0x43bc405AF6C39250d79137B1Ec2f3E13BE0D30F8",
"0x44c35F9E90fB412e8689bbF80fF5D67Bf578859c",
"0x02531B0f47755F23Fd230fC58262cC4941356666",
"0x7557B3d09D53c9647127D674806DCd317de35129",
"0x9222cF504c11DCDC8642192917274b7A9884ea67",
"0x99B981bc363e631256a7Ee509bBa34c76B371b4f",
"0x8270834B321431379B1d8E142136074308F8b717",
"0x6914785237E84C0b7e7c53530680029A6Fef2Bf7",
"0x1308B6DB347900EaA4A1101a8512625Bb2489113",
"0x2359D89a24bF7fC3AF74F3d1715306bBd33d4dB5",
"0x1502316d29bc2A6BBa3B74c3e92ED4516B6F61C1",
"0xe14837f51F325A31923Af89D43987d0de785DF1A",
"0x80Acb3b55132C4fe408f8BCDa583AAF98824e206",
"0xF01D40C0854010862cf40C256f60D0082B611c09",
"0x8c34086632DD804711Ca12F21dDC0e27e85b548e",
"0x18E0f9AAdde970D74430cC8636A381CcFCD1F559",
"0x336d5Dd0bdf69a0C29B21A0e0C34BfCF017a9610",
"0xCcb213F480966db5EcDdd18B92b20B6a5EeF03D3",
"0xBeb0737585bd8a9306e2777966d413bF3735861B",
"0xaf9E18cd797548220317fB459095C6afac5C33A7",
"0xBB5106aDC9Db79a766d6D707546dEa5d8934f314",
"0xcC811209c43372Ac166B14c526455A2618493c05",
"0x01b7baA7baA864fEF3CD1C7bc118Cc97cEdCB33f",
"0x1fef4E18daEE931B3AC7a8AB69546C4D511d8Ede",
"0x0688567b794A2A50909ca6FE92BCBe7A018556B6",
"0x56dB1201d1A37c9b3F2F8be600cbca6AC656473B",
"0x11065895c4c25eD56d6303Bc8848c068411C8cfF",
"0xaF570D6E2D1d5B39fB9dB2Eb012d5fa80BBAF2ef",
"0x297E3e1b23CdF212d93885CdeA78fAD626b31cb7",
"0xBB572f990012649FBb32f37E9901361ec91BB851",
"0x104d39c07F2C2b29b2104EA39C15730146A70d4c",
"0x559de301EffC4338b2805f79B4e815F387332d23",
"0x9A12B9e1A7542311f971511567b4d679E93553d7",
"0xE90AEA7a6CC6fd92AAFe7FF57cB2B4908eEA7aBa",
"0xe88fa1221378D988B39F54288f4149FD2E7CEa79",
"0xf98d8cA42AF1BF0EB3bf74443e011C5372c80643",
"0xB6d844d84b2955D6aD07a198504DB8686677b2e6",
"0xB5bBFFD7D19e0Ed0e4e29F86fB6C70a8379666d4",
"0x9a0AEF6bDEc9735385ABe70055D7206002a85EeC",
"0x67691771270F0199BfE54a00F8343d15aFc5b872",
"0xc010D451052EdD44Df7aB1A1D5E1DEc352241069",
"0xA93ff05d277d896d10A4172C2dC19D70730E8bDc",
"0x4d7ac4D0cD2473371508da5A95bd6331bc58CA8c",
"0x504058395104903a0956f54f018Be60404873f31",
"0xEb3e76BAa400bFFEb4134a8fcFcDEFFdfcBa4385",
"0x5067cA1372098f81275cE720A1e9A5188a90e8D8",
"0x2b538E17628E68703B9527D4A3951F093A11Cc76",
"0xA4D3786210A59CC113eeDf5a30e1358Ed9BeFa2A",
"0x16Cd19A4C006405b93C5740db696ed507653d07B",
"0xa62aacbe7b5b4a94c4E55a03a9b815df84322d40",
"0xe46B7EF8f7e225861676459C989D096CE6615B44",
"0x87772fF7C65B5c93Be52e3504670c6407271F829",
"0x42DAaCea431993D938bfEB85EB73e3537C498A56",
"0xDBBf9e71d820e83A44009e41659f697557d89Cd5",
"0x3b36364791d39c977d86cd823FFC4149a9038907",
"0xDA7f44bAA585EB2b72eDFBeCa77980AF69F33758",
"0x5C0AEe64C41B0a45E030DCE2A546ac39886299BB",
"0x794603C1a0c4799b04Cd8F9406D2b201286CE63A",
"0x86B56FeAa7C851b666562B28f9ec4d502A8D4DC1",
"0x2D91Cc97E84a31a678F8696135881988D71BE6aB",
"0xF3c52e30eDb0405CC182980C5c1977C9f8F41cB5",
"0x7B520167A75128330Ea2b6e96ddEcfD3901c0816",
"0x732739De18DF630b354EC365a927a4f4F2BF28E5",
"0xa8dC82F361f86A60e5c596aDEc78545D99633188",
"0xA9E7F4C62b61A54F6614FC4e2a6071bd3107398f",
"0x183AbE67478eB7E87c96CA28E2f63Dec53f22E3A",
"0x9b717Cf7313315f27E8FA93c822f3e9CDa07E8B2",
"0xb6AE340066ACD36747a0f61555AE50e44785C53B",
"0x79635b386B9bd6636Cd701879C32E6dd181C853F",
"0x916168165731f0281881600E6647d0035350d7C8",
"0xf33D6481b0C227AFbFC9a8B2765A47f0f06C3D2F",
"0xF34196597dF209A04Fc92B97071DCAAbbC63f0E8",
"0x6DD5Afb174a12D93112E0af81eDA6c40bef0A03B",
"0x68233a257c6Aa560cE3BEe5D84D4881128DC97B5",
"0xd79a9865F5866760B77D7f82e35316662dEC6793",
"0x4cf41C1E5e2A8403E08e7d3883791fCe6f6497d3",
"0x4026949c068A96604D09077a6f0A1d1d0dbF1CD0",
"0xF79765D064B86648C7C01F55f9eCB5ec5D3A7d4b",
"0xa97c7af7532e661DB802069571920A718338618d",
"0x802A662F1C1388c0045DcCbcFae580f96725Beaf",
"0xF1e44Ff35b14c7Da354240997f43C750DDd1e89E",
"0x7e8B97340F2cD42cba3aB7f207235C57cF89370a",
"0x14f1d835AeCf747ACAd839B23fd1B7f2d8791D42",
"0x1C4F979e43e010f4fCAba80B4382b853D1AF8758",
"0x4a347d1D36c7A5BE7D2e59e1E34aF373BE96e6Bd",
"0x06e13bd0a3Cba08E61028F2326b9eA2Ca2539900",
"0x774DD298809867f9e03CD2Ae036A5c769d1C74E4",
"0x29e262bb27D945B2643B4947954ddACE9A412688",
"0x206105E59b7EFF2c2f5eaFFe68803814CA970E65",
"0xFe5637695C2198Dfd9FcD3453943c1E7E0C1a6de",
"0x5289156F8B0876E7576FE960E39a3E84c90D821f",
"0x9a9149f5464C9Ac87CcF1bc5BC767d2d51d0043c",
"0x661Ea32f349F857075CAE289E7F6222c2AD083b9",
"0xf8B539d40657D56CF505b1c00c5181aCdfD146D0",
"0x070fDF4d4EeD5A4cBaFc2E302B3DCF029E6D2cAc",
"0x8dF9719d29AAcac7E46dB46974f58Ad953352c42",
"0xeD03eB80F1e8D5cAcBE80b8d1D4dB599F32C41A2",
"0x3118fBe9dDfD9bA217a0BD0c39CAc257Fec022b9",
"0x04a57d13239c29B8F9AEEb57ABAb5F8B6BbcEFf9",
"0x9EAB9D856a3a667dc4CD10001D59c679C64756E7",
"0x6b611D278233CB7ca76FD5C08579c3337C01e577",
"0x16a4e0790f14301A3DC32CB7D39C6784E21eEDe8",
"0x61d267C6Bc172Ad97A5a86D0369e60aD0C2eF88F",
"0xe60a7BD035b37BA6B3d0f0fe55B11A66fec57Eaf",
"0xd1380A4e089950ee3a23d818E24ccBbEf003a432",
"0xB6218ED3763b5f0e65AaC362259aDB0c73F33570",
"0xdd6D3324C75A5aE1480Ef9f3394702dc28adb65E",
"0x7FCE5E3d6f46C10790372e11a954455C27269835",
"0xDd72b460B44dE68d0b4EF72650A2fc71808C276F",
"0xc744Cf8f58DB2D7dEC3e9251008dE7f449E87b8c",
"0x64166B7DEd8884eDa0C52Ee26c04124892f0855e",
"0x395e1B297930f40a2d3643Bf947D60A61619F716",
"0x0a25BC2ec390D3D90457a5eFA1aAE20404422f7C",
"0x8513FAfE1813B6ec3BBD9fc4BAF5340bda8D670B",
"0x5Ba2d61c749de67bC5b27CCF284e8b161231BaD0",
"0x554e0B456a38885501D519299ef0D0ba9715d0b1",
"0x0219Ab3584db4b71Fc90a8F861b86f2a384A7eC0",
"0xA0326754b524b980cC5cc48b6F18f494D95270f8",
"0xAE287F5d696B9f3B33FA08915B6DCd827Cd4E211",
"0x57aF2c4249761f4B266dbd80fE44061fAf4780C3",
"0x35273851AcBd29eC6e83a3660E312f525a6b5E9a",
"0xDd31dB93082a3A71b98D37ba26230f8734Bd63C3",
"0xb06013f0AD4797D912EE80CD154E171b5D96BA22",
"0x27F8602E403B6EA18f8711A7858fa4a94ef3269b",
"0xEA4F2CEcfF52bb881d4aD0DAc642e74b77b7f5a0",
"0xc93f19D748305b4dB0b7Fb68ed5b8a39A2CB74a3",
"0x552870BBc31186581644922Ea9Ab8B48a5D08d26",
"0x34E3f9567aee97397Ac7A002dF2ef4f30193F1A6",
"0x0db7b27b7A3Ea8035D3F66F3ba7532221eF06abE",
"0x057D1e845402b371EB793135F877966B47577e28",
"0xd05679f764e526d2024258F19b49f8f747cA6922",
"0x6b7e9f34D9b18D8fAF1A69a529f85E594d70a2ee",
"0x4D394e7A328eAEA2b15Bb127F10b485d3e543EA4",
"0x0935793Ef5E84415cd9E5D8689c33470391A74Ce",
"0xFB7350273af4Bdd1707E8759E3949F65d868EB0B",
"0x7BB09046C5835B3e7eE83c3Fddb51b0aC51BAE7C",
"0x7C1000C94067ff92EF9E9997BB77cFfF362182CD",
"0x61D48840dbEeB59Be424A4624077cA62593Ce0c0",
"0xb86b6c777D3e39C81652c0a73B661DcdB07d6164",
"0xD6aDbff66604739ec6c6a6f91cAb0E8AaF4c85d8",
"0xf856B88096C08410BB5ec295b04526aB316142CB",
"0x294AED5e032973987d6DF2f5434A4652C5Cd6054",
"0x1e44341e7C349cf9C7F603ff165612d7C2bFb423",
"0x6cA9d147e2F64e45b0BB4F83962f179f66c883aB",
"0xA53571418177DcCcF331ED3774EBAe846Bd06B1D",
"0x2B2447DB1DABa1f51c6046D2A9da26be07375367",
"0x9a9C09178F2647883A8EecF885a396A8cB48866c",
"0xaA6bb45752624Cc225ad763970A21494CbF9E9dC",
"0x9ac16a11B23B2fee4848Cc2B16516D73D9a8F57A",
"0x48F2820595848a31267c7d3525863846A59c05fB",
"0xa5Deab03b72d12fEE46D3CbC8606EaE60293b01C",
"0xEAC2e1d55f687D2b11d6E41cC9eE0f4d2Cef3c01",
"0xFE91BB5176f645Cdb34d5a03c3F5aa6F4629e141",
"0x0b6F2b5FE6680375a9f932c1E684a2F5C162872b",
"0x38354F29BcDD5D698f9AbaC219Ea01bB9c55e084",
"0xf8B3b8432aB49Fe071F386f0917981994d7a164D",
"0xb1e4B15635bEcf67490E687550E9E9F213D022Dc",
"0x2290420DCEcEB0F09bfDD1f95110fb3Ea31Bda9E",
"0x5c43555DB810eCA0F8704CD39D3630D6eCedcB7D",
"0xc6A5FF80198B54BD921CF8b4fFB7aD89Bc40F527",
"0x859ABfAe3fb8a5Ce4378D910638bAd4dCf40A9cB",
"0xC20773Fa34eFBc1969C16a28D43498d3bE9D617A",
"0xD8404763Bce69672B42d9409f3aC9B4b2E684CFb",
"0xd089b6DdA7c25b4738Cd953ec72dC89641c95906",
"0xcdf30b1bb92016217955eE64F727b6C238632cb2",
"0xE64A4723b7F704f2e976Ca868d9cDAe5Be9A533d"
]

const official_whitelist:string[] = [
    ...manual_add, ...snapshot
]

export {official_whitelist}