import { useEffect, useRef, useState } from "react"
import { FramedCanvas } from "../CanvasView"
import { getSVG, getSVGWithCheckpoint } from "../Models/Canvas"
import { MintAction, PossibleActionTypes } from "../Models/MintAction"
import {isBadBrowser} from "../../lib/badBrowser"
import { Bid } from "../../lib/useAllBidsForJackson"
import GalleryBids from "./GalleryBids"
import { leftArrow, rightArrow } from "../Website/Icons"
import auctionBidsCache from "../../tokenCache/auctionBidsCache.json";

function ViewAllHero(
    {
        mintActions, 
        viewIndex, 
        setViewIndex,
    } 
    : {
        mintActions: MintAction[], 
        viewIndex: number, 
        setViewIndex: (index: number) => void
    }) {

    const _isBadBrowser = isBadBrowser();
    const [isPlaying, setIsPlaying] = useState(false);
    const [selectedBid, setSelctedBid] = useState<Bid | null>(null);

    const playingRef = useRef(isPlaying);
    playingRef.current = isPlaying;

    useEffect(() => {
        if (!isPlaying) return;
        
        if (viewIndex == mintActions.length -1) {
            setIsPlaying(false);
        }
        else {
            setTimeout(() => {
                if (playingRef.current) setViewIndex((viewIndex + 1));
            }, MintAction.ANIMATION_DURATION_MS)
        }
        
    }, [viewIndex])

    useEffect(() => {
        if (isPlaying) {
            setViewIndex( (viewIndex == mintActions.length - 1) ? 0 : viewIndex + 1)
        }
    }, [isPlaying])

    const mintActionsToPreview = selectedBid ? [...mintActions.slice(0, viewIndex), selectedBid?.mintAction] : mintActions;
    
    const SVG = getSVGWithCheckpoint(mintActionsToPreview, true, viewIndex);

    return <div className="flex relative text-black">
        <div className="container mx-auto px-8">
            <div className="w-full flex flex-col items-center pt-8">

                <div className="w-full flex flex-col sm:flex-row justify-center">
                    <div className="w-full sm:w-[70vh] relative flex flex-col items-center pr-8">

                        <FramedCanvas SVG={SVG} />
                        
                    </div>
                    <div className="w-full sm:w-[300px] pt-12">
                        <ArrowButtons 
                            onLeft={(e: any) => setViewIndex(viewIndex > 0 ? viewIndex - 1 : viewIndex)}
                            onRight={(e: any) => setViewIndex(viewIndex < 260 ? viewIndex + 1 : viewIndex)}
                            currentViewIndex={viewIndex}
                            />
                        <div className="text-4xl font-catamaran font-bold">
                            Hour#{viewIndex}
                        </div>
                        <GalleryBids selectedBid={selectedBid} tokenId={viewIndex} onSelect={setSelctedBid}/>
                    </div>
                </div>
                <div className="hidden sm:flex w-full sm:w-3/4 flex-row items-center justify-center pt-8">
                    <PlayButton isPlaying={isPlaying} onClick={() => {setIsPlaying(!isPlaying)}} />
                    
                    <div className="relative w-full">
                        <input type="range" min="0" max={mintActions.length - 1} 
                            value={viewIndex} 
                            onChange={(e) => {setViewIndex(parseInt(e.target.value)) }}
                            className="w-full m-4 myslider appearance-none bg-gray-700 h-[2px] rounded-full 
                                        opacity-70 hover:opacity-100" />
                        
                        <div className="w-full m-4 absolute left-0 bottom-1 ">
                            <BidHistogram selectBar={setViewIndex}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
}

const BidHistogram = ({selectBar}:{selectBar: (bar:number) => void}) => {
    var bars = [];
    for (let i = 0; i < auctionBidsCache.length; i++) {
        const height = auctionBidsCache[i].bids.length * 2;
        
        bars.push(
            <div 
                key={i} 
                style={{height: height}} 
                className={`w-[3px] bg-black/20 hover:bg-black/50 transition-all cursor-pointer`}
                onClick={(e) => selectBar(i)}>
            </div>)
    }
    
    return <div className="flex flex-row w-full items-end justify-between px-1">
            {bars}
        </div>
}


function PlayButton({isPlaying, onClick}: {isPlaying: Boolean, onClick: () => void}) {
    const getIcon = () => {
        if (!isPlaying) {
            return <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M300.782 890.48V261.52L795.611 576 300.782 890.48Z"/></svg>
        }
        else {
            return <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 96 960 960" width="24"><path d="M519.435 890.48V261.52H794.48v628.96H519.435Zm-353.915 0V261.52h275.045v628.96H165.52Z"/></svg>
        }
    }
    return <div onClick={onClick} className="cursor-pointer fill-neutral-600">
        {getIcon()}
    </div>
}

const ArrowButtons = (
    {
        onLeft, 
        onRight,
        currentViewIndex,
    }
    : {
        onLeft: (e:any) => void,
        onRight: (e:any) => void,
        currentViewIndex: number,
    }) => {
        return (
            <div className="-ml-2 flex flex-row">
            {currentViewIndex > 0 && <div className="p-1 fill-neutral-600 border-2 rounded-full border-black/0 hover:border-black hover:fill-neutral-800 transition-all cursor-pointer"
                onClick={onLeft}>
                {leftArrow}
            </div>}
            
            {currentViewIndex < 259 && <div className="p-1 fill-neutral-600 border-2 rounded-full border-black/0 hover:border-black hover:fill-neutral-800 transition-all cursor-pointer"
                onClick={onRight}>
                {rightArrow}
            </div>}
    </div>)
}
     
        
    

export {ViewAllHero}