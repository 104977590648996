import ArtParams from '../../ArtParams.json';

enum ActionType {
    Rectangle = ArtParams.MINT_TYPE_RECTANGLE.value,
    Circle = ArtParams.MINT_TYPE_CIRCLE.value,
    Rotate = ArtParams.MINT_TYPE_ROTATE.value,
    Quad = ArtParams.MINT_TYPE_QUAD.value,
    Shear = ArtParams.MINT_TYPE_SHEAR.value,
    Background = ArtParams.MINT_TYPE_BACKGROUND.value,
    WindowRectangle = ArtParams.MINT_TYPE_WINDOW_RECTANGLE.value,
    WindowCircle = ArtParams.MINT_TYPE_WINDOW_CIRCLE.value,
    Tile = ArtParams.MINT_TYPE_TILE.value,
    Scale = ArtParams.MINT_TYPE_SCALE.value,
    Italicize = ArtParams.MINT_TYPE_ITALICIZE.value,
    FinishingTouch = 256,
}

const ActionTypesToParams: { [key in ActionType]: string[] } = {
    [ActionType.Rectangle]: ["x", "y", "width", "height", "opacity", "fill"],
    [ActionType.Circle]: ["cx", "cy", "r", "opacity", "fill"],
    [ActionType.Rotate]: ["rotationDegrees"],
    [ActionType.Quad]: ["amount"],
    [ActionType.Background]: ["fill"],
    [ActionType.Shear]: ["amount", "slices"],
    [ActionType.WindowRectangle]: ["x", "y", "width", "height"],
    [ActionType.WindowCircle]: ["cx", "cy", "r"],
    [ActionType.Tile]: ["x", "y", "width", "height"],
    [ActionType.Scale]: ["scaleX", "scaleY"],
    [ActionType.Italicize]: ["amount"],
    
}

const ActionTypeToName: { [key in ActionType]: string } = {
    [ActionType.Rectangle]: "Rectangle",
    [ActionType.Circle]: "Circle",
    [ActionType.Rotate]: "Rotate",
    [ActionType.Quad]: "Quad",
    [ActionType.Shear]: "Shear",
    [ActionType.Background]: "Background Color",
    [ActionType.WindowRectangle]: "Window Rectangle",
    [ActionType.WindowCircle]: "Window Circle",
    [ActionType.Tile]: "Tile",
    [ActionType.Scale]: "Scale",
    [ActionType.Italicize]: "Italicize",
}

const PossibleActionTypes = [
    ActionType.Rectangle,
    ActionType.Circle,
    ActionType.Rotate,
    ActionType.Quad,
    ActionType.Shear,
    ActionType.Background,
    ActionType.WindowRectangle,
    ActionType.WindowCircle,
    ActionType.Tile,
    ActionType.Scale,
    ActionType.Italicize,
]

const TargetRarity: { [key in ActionType]: number } = {
    [ActionType.Rectangle]: 1,
    [ActionType.Circle]: .7,
    [ActionType.Rotate]: .15,
    [ActionType.Quad]: .04,
    [ActionType.Shear]: .04,
    [ActionType.Background]: .08,
    [ActionType.WindowRectangle]: .06,
    [ActionType.WindowCircle]: .06,
    [ActionType.Tile]: .04,
    [ActionType.Scale]: .1,
    [ActionType.Italicize]: .1,
}

const ALLOWLIST_MULTIPLIER = 2;

const percentToHexByte = (percent: number) => {
    if (percent >= 1) return "FF";
    return Math.trunc(percent * 255).toString(16).padStart(2, "0");
}

const rarity100Bytes = ():string => {
    var total = "0x";
    PossibleActionTypes.forEach( arg => {
        total += "FF";
    })
    return total;
}

const targetRarityBytes = ():string => {
    var avail = "0x"
    PossibleActionTypes.forEach( arg => {
        avail += percentToHexByte(TargetRarity[arg]);
    });
    return avail;
}

const targetRarityALBytes = ():string => {
    var avail = "0x"
    PossibleActionTypes.forEach( arg => {
        avail += percentToHexByte(TargetRarity[arg] * ALLOWLIST_MULTIPLIER);
    });
    return avail;
}


abstract class MintAction {

    static ANIMATION_DURATION_MS = 1200;
    static ANIMATION_TIMING = ` dur="${this.ANIMATION_DURATION_MS}ms" calcMode="spline" keyTimes="0;1" keySplines="0.5 0 0.5 1" fill="freeze" `

    getBytes32(): string {
        let s = "0x";
        s += this.createByteString();
        for (var i = s.length; i < 66; i++) {
            s += "0"
        }
        return s;
    };

    getBytes32FinishingTouch(): string {
        let bytes32 = this.getBytes32();
        return bytes32.substring(0, bytes32.length - 2) + "01";
    }

    static isFinishingTouch(bytes32:string): boolean {
        return bytes32.substring(bytes32.length - 2) === "01";
    }

    abstract createByteString(): string;
    abstract getSVG(): string;
    abstract getAnimatedSVG(): string;
    abstract getSummary(): string;
    abstract getActionType(): ActionType;
}

export { MintAction, ActionType, ActionTypesToParams, PossibleActionTypes, ActionTypeToName, TargetRarity, targetRarityBytes, targetRarityALBytes, rarity100Bytes, ALLOWLIST_MULTIPLIER};