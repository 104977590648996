import { Background } from "./Background";
import { RotateEffect, ShearEffect, QuadEffect, WindowRectangleEffect, WindowCircleEffect } from "./Effect";
import { ItalicizeEffect } from "./Effects/Italicize";
import { ScaleEffect } from "./Effects/Scale";
import { TileEffect } from "./Effects/Tile";
import { ActionType, MintAction } from "./MintAction";
import { Circle, Rectangle } from "./Shape";

function createMintAction(type: ActionType, params: any, hourId: number): MintAction {
    switch (type) {
        case ActionType.Rectangle: return new Rectangle(params);
        case ActionType.Circle: return new Circle(params);
        case ActionType.Rotate: return new RotateEffect({ ...params, id: hourId });
        case ActionType.Quad: return new QuadEffect({...params, id: hourId});
        case ActionType.Shear: return new ShearEffect({...params, id: hourId});
        case ActionType.Background: return new Background({...params});
        case ActionType.WindowRectangle: return new WindowRectangleEffect({...params, id: hourId});
        case ActionType.WindowCircle: return new WindowCircleEffect({...params, id: hourId});
        case ActionType.Tile: return new TileEffect({...params, id: hourId});
        case ActionType.Scale: return new ScaleEffect({...params, id: hourId});
        case ActionType.Italicize: return new ItalicizeEffect({...params, id: hourId});
        
    }
    throw new Error("unrecognized action type!");
}

function createMintActionFromByteString(byteString: string, hourId: number): MintAction {
    const actionType = parseInt(byteString.substring(0, 2), 16);
    switch (actionType) {
        case ActionType.Rectangle: return Rectangle.fromByteString(byteString);
        case ActionType.Circle: return Circle.fromByteString(byteString);
        case ActionType.Rotate: return RotateEffect.fromByteString(byteString, hourId);
        case ActionType.Quad: return QuadEffect.fromByteString(byteString, hourId);
        case ActionType.Background: return Background.fromByteString(byteString);
        case ActionType.Shear: return ShearEffect.fromByteString(byteString, hourId);
        case ActionType.WindowRectangle: return WindowRectangleEffect.fromByteString(byteString, hourId);
        case ActionType.WindowCircle: return WindowCircleEffect.fromByteString(byteString, hourId);
        case ActionType.Tile: return TileEffect.fromByteString(byteString, hourId);
        case ActionType.Scale: return ScaleEffect.fromByteString(byteString, hourId);
        case ActionType.Italicize: return ItalicizeEffect.fromByteString(byteString, hourId);
    }
    throw new Error("unrecognized action type!");
}

function createRandomAction(hourId: number): MintAction {
    const available_actions = [ActionType.Rectangle, ActionType.Circle, ActionType.Rotate];// ActionType.Shear, ActionType.Quad];
    var action = available_actions[Math.floor(Math.random() * available_actions.length)];
    return createMintAction(action, undefined, hourId);
}

const createRandomActions = (n:number) => {
    var actions:MintAction[] = [];
    for (var i = 0; i < n; i++) {
        actions.push(createRandomAction(i));
    }
    return actions;
}

export { createMintAction, createRandomAction, createMintActionFromByteString, createRandomActions };